<template>
  	<transition-group name="fade">
  	
      <div class="row" key="main">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

          <div class="c-boxed c-rounded c-shadow" data-background="white">

            <h1>{{ $t('menu.dataprotection') }}</h1>

            <!-- <div v-html="$t('static.content.'+this.content)"></div> -->

            <div v-if="locale == 'de'">
              <p><strong>1.  Allgemeine Hinweise</strong><br/>
                Wir legen grössten Wert auf den Schutz Ihrer Daten und die Wahrung Ihrer Privatsphäre. Nachstehend informieren wir Sie deshalb über die Erhebung und Verwendung persönlicher Daten bei der Nutzung unserer Applikation.</p>
              <p>Diese Datenschutzerklärung wird durch Würth AG herausgegeben und hält sich an das Bundesgesetzt über den Datenschutz. Mit der Nutzung unserer Website stimmen Sie der Verarbeitung Ihrer personenbezogenen Daten nach Massgabe der nachfolgenden Bestimmungen zu.</p>
              <p><strong>2.  Von uns erhobene Daten und die Art der Verwendung / Rechtsgrundlage der Verarbeitung</strong><br/>
              Bei dem Besuch unserer Applikation erheben, verwenden und speichern wir Ihre personenbezogenen Daten. Personenbezogene Daten sind Informationen, Einzelangaben über persönliche oder sachliche Verhältnisse, die sich auf Sie beziehen oder welche wir als Würth AG auf andere Weise mit Ihnen in Verbindung bringen können.</p>
              <p><strong>3.  Datenerhebung unter strenger Beachtung der Gesetze / Wie sammeln wir Ihre Personendaten</strong><br/>
              Wir sammeln personenbezogene Daten, die Sie uns freiwillig übermittelt haben, wenn Sie beispielsweise mit uns kommunizieren via E-Mail, Post oder Telefon;</p>
              <ul>
                <li>wenn Sie uns bitten, Ihnen Mitteilungen oder anderen Informationen zu schicken</li>
                <li>Leistungen von uns beantragen oder etwas in Auftrag geben</li>
              </ul>
              <strong>Wofür nutzen wir Ihre Daten?</strong><br/>
              Wir nutzen Ihre Daten rein zur von Ihnen geforderten Auftragserfüllung und Lieferung von Waren.</p>

              <p><strong>4.  Hinweise zur verantwortlichen Stelle</strong><br/>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/><br/>
              Würth AG<br/>
              Dornwydenweg 11<br/>
              4144 Arlesheim<br/>
              E-Mail:   professionalcarcare@wuerth-ag.ch</p>

              <p><strong>5.  Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong><br/>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
              <p>Sie haben jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen. Die Rechtsgrundlage, auf denen eine Verarbeitung beruht, ist das Bundesgesetzt für Datenschutz. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

              <p><strong>6.  Ihre Rechte nach dem Datenschutzgesetzt</strong><br/>
              <strong>Auskunft, Sperrung, Löschung und Berichtigung</strong><br/>
              Gemäss Bundesdatenschutzgesetz hat jede Person das Recht zu erfahren, welche Daten über sie gespeichert sind.  Darüber hinaus kann jede Person beantragen diese Daten anpassen oder gar löschen zu lassen. Dies kann durch die Person, welche das wünscht, aktiv bei der oben genannten verantwortlichen Stelle beantragt werden. Eine E-Mail genügt hier bereits. </p>

              <p><strong>7.  Links zu anderen Websites</strong><br/>
              Unsere Websites und Anwendungen enthalten Links zu Websites, die von der Würth AG betrieben und kontrolliert werden. Der einheitliche Ressourcenverweis (Uniform Record Locator, URL) gibt Ihnen stets darüber Aufschluss, ob sich die besuchte Seite auf einer unserer Websites befindet. Es empfiehlt sich, die Datenschutzerklärungen dieser Websites durchzugehen, da diese abweichen können.</p>

              <p><strong>8.  Sicherheit und SSL- TLS- Verschlüsselung</strong><br/>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von http:// auf https:// wechselt und am Schloss-Symbol in der Browserzeile.</p>

              <p><strong>9.  Speicherung Ihrer Daten in unseren Systemen, Dauer und Sicherung der Daten</strong><br/>
              Um eine seriöse, statistische Analyse machen zu können, werden nicht personenbezogene Daten bis zu 1 Jahr gespeichert. Je nach vordefinierten Intervallen werden die Daten (Logfiles) vom Hoster auch bereits nach einer Woche gelöscht.</p>

              <p>Wir treffen angemessene organisatorische und technische Massnahmen, um Personendaten vor Manipulation, Verlust, Zerstörung oder unbefugtem Bearbeiten zu schützen.</p>

              <p>Würth AG übernimmt jedoch keine Haftung für Schäden infolge Datenverlustes oder Kenntnisnahme und Bearbeitung von Personendaten durch Unbefugte.</p>

            </div>

            <div v-if="locale == 'fr'">
              <p><strong>1.  Remarques générales</strong><br/>
              Nous accordons une importance primordiale à la protection de vos données personnelles et à la préservation de votre sphère privée. C'est pourquoi nous vous informons ci-après au sujet de la collecte et de l'utilisation de vos données personnelles lors de l'utilisation de notre application.</p>

              <p>La présente déclaration de confidentialité éditée par Würth AG suit les prescriptions de la Loi fédérale sur la protection des données. En utilisant notre site web, vous consentez au traitement de vos données à caractère personnel conformément aux dispositions suivantes.</p>

              <p><strong>2.  Les données que nous collectons et la nature de leur utilisation / fondement légal du traitement</strong><br/>
              Nous utilisons et stockons vos données à caractère personnel lorsque vous visitez notre application. Les données à caractère personnel sont des informations, des renseignements sur la situation personnelle ou matérielle qui se rapportent à votre personne ou que nous, Würth AG, pouvons d'une autre manière mettre en relation avec vous.</p>

              <p><strong>3.  Collecte des données dans le strict respect de la loi / comment collectons-nous vos données à caractère personnel?</strong><br/>
              Nous collectons les données à caractère personnel que vous nous avez transmises de plein gré, par exemple en communiquant avec nous par e-mail, par courriel postal ou par téléphone;</p>
              <ul>
              <li>quand vous nous priez de vous faire parvenir des notifications ou d'autres informations</li>
              <li>quand vous nous demandez des prestations ou quand vous nous passez une commande</li>
              </ul>
              <p><strong>À quelles fins utilisons-nous vos données?</strong><br/>
              Nous utilisons vos données exclusivement dans le but d'exécuter votre commande et de livrer les marchandises.</p>

              <p><strong>4.  Indications relatives au service responsable</strong><br/>
              Le responsable du traitement des données de ce site web est:<br/><br/>
              Würth AG<br/>
              Dornwydenweg 11<br/>
              4144 Arlesheim<br/>
              E-Mail:   professionalcarcare@wuerth-ag.ch</p>

              <p><strong>5.  Révocation de votre consentement au traitement des données</strong><br/>
              De nombreux processus de traitement de données ne sont possibles qu'avec votre consentement explicite. Vous pouvez à tout moment révoquer un consentement déjà accordé. Une simple communication que vous nous faites parvenir par e-mail suffit. La révocation ne touche pas la légitimité du traitement des données effectué jusqu'à la révocation.</p>

              <p>Vous pouvez à tout moment, pour des raisons résultant de votre situation particulière, vous opposer au traitement de vos données à caractère personnel. Le fondement légal du traitement est la Loi fédérale sur la protection des données. Si vous vous y opposez, nous ne traiterons plus les données à caractère personnel qui vous concernent, à moins que leur traitement serve à constater, à exercer ou à défendre des droits.</p>

              <p><strong>6.  Vos droits aux termes de la Loi sur la protection des données</strong><br/><br/>

              <strong>Renseignement, blocage, effacement et rectification</strong><br/>
              Aux termes de la Loi fédérale sur la protection des données, toute personne a le droit de savoir quelles données la concernant sont enregistrées.  Par ailleurs, toute personne peut demander que ces données soient rectifiées, voire effacées. La personne qui le souhaite peut en faire la demande activement auprès du service responsable mentionné plus haut. Un e-mail à ce sujet suffit. </p>

              <p><strong>7.  Liens vers d'autres sites web</strong><br/>
              Nos sites web et nos applications contiennent des liens vers d'autres sites web exploités ou contrôlés par Würth AG. Le localisateur de ressource uniforme (Uniform Record Locator, URL) vous indique toujours si la page que vous visitez appartient à nos sites web. Il est recommandé de prendre connaissance des déclarations de confidentialité de ces sites web car celles-ci peuvent diverger.</p>

              <p><strong>8.  Sécurité et cryptage SSL / TLS</strong><br/>
              Nous mettons en œuvre un cryptage SSL ou TLS pour des raisons de sécurité et dans le but de protéger la transmission de contenus confidentiels comme des commandes ou des demandes que vous nous faites parvenir en notre qualité d'exploitant du site. Vous pouvez identifier une liaison cryptée à la présence de https:// au lieu de http:// dans la ligne d'adresse et au symbole de cadenas dans la ligne du navigateur.</p>

              <p><strong>9.  Stockage de vos données dans nos systèmes, durée et sécurité des données</strong><br/>
              Dans le but de pouvoir effectuer une analyse statistique sérieuse, les données qui ne sont pas à caractère personnel sont stockées pendant une durée pouvant atteindre un an. Selon les intervalles définis par avance, l'hébergeur peut effacer les données (logfiles) après déjà une semaine.</p>

              <p>Nous prenons au plan organisationnel et technique des mesures destinées à protéger les données à caractère personnel de la manipulation, de la perte, de la destruction ou d'un traitement non autorisé.</p>

              <p>Würth AG n'endosse cependant aucune responsabilité pour les dommages consécutifs à une perte de données ou pour la prise de connaissance et le traitement de données à caractère personnel par des personnes non autorisées.</p>
            </div>

            <div v-if="locale == 'it'">
              <p><strong>1.  Avvertenza generale</strong><br/>
              Attribuiamo grande importanza alla protezione dei vostri dati e alla vostra sfera privata. Vi informiamo pertanto di seguito sulla raccolta e sull’utilizzo dei dati personali durante l’utilizzo della nostra applicazione.</p>
              <p>La presente dichiarazione sulla protezione dei dati è pubblicata da Würth AG ed è conforme alla legge federale sulla protezione dei dati. Utilizzando il nostro sito web, acconsentite al trattamento dei vostri dati personali in conformità con le disposizioni esposte di seguito.</p>
              <p><strong>2.  Dati raccolti da noi e modalità di utilizzo / base giuridica del trattamento</strong><br/>
              Quando utilizzate la nostra applicazione, noi raccogliamo, utilizziamo e conserviamo i vostri dati personali. I dati personali sono informazioni, dettagli individuali su circostanze personali o oggettivi che si riferiscono a voi o che, come Würth AG, possiamo associare a voi in qualche altro modo.</p>
              <p><strong>3.  Raccolta dei dati in stretta conformità con la legge / come raccogliamo i vostri dati personali</strong><br/>
              Raccogliamo i dati personali che ci avete fornito volontariamente, per esempio, quando comunicate con noi via e-mail, posta o telefono;</p>
              <ul>
              <li>quando ci chiedete di inviarvi avvisi o altre informazioni</li>
              <li>quando richiedete servizi o ci assegnate un ordine </li>
              </ul>
              <p><strong>Per quali scopi usiamo i vostri dati?</strong><br/>
              Utilizziamo i vostri dati al solo scopo di eseguire il vostro ordine e consegnare la merce come richiesto da voi.</p>
              <p><strong>4.  Informazioni sull'organismo responsabile</strong><br/>
              L’ufficio responsabile per il trattamento dei dati su questo sito internet è:<br/><br/>
              Würth AG<br/>
              Dornwydenweg 11<br/>
              4144 Arlesheim<br/>
              E-mail:   professionalcarcare@wuerth-ag.ch</p>
              <p><strong>5.  Revoca del vostro consenso al trattamento dei dati</strong><br/>
              Molte operazioni di trattamento dei dati sono possibili solo con il vostro esplicito consenso. In qualsiasi momento potete revocare il consenso precedentemente concesso. A tale scopo è sufficiente che ci inviate un’e-mail informale. La legittimità del trattamento dei dati effettuato fino alla revoca rimane inalterata dalla revoca.</p>
              <p>In ogni istante avete il diritto di opporvi al trattamento dei vostri dati personali per motivi legati alla vostra situazione particolare. La base giuridica su cui si basa il trattamento è la legge federale sulla protezione dei dati. Se vi opponete, non tratteremo più i vostri dati personali interessati, a meno che il trattamento non serva a far valere, esercitare o difendere rivendicazioni legali.</p>
              <p><strong>6.  I vostri diritti secondo la legge sulla protezione dei dati</strong><br/><br/>
              <strong>Informazioni, blocco, cancellazione e correzione</strong><br/>
              Secondo la legge federale sulla protezione dei dati, ogni persona ha il diritto di sapere quali dati che la concernono sono conservati. Ogni persona può inoltre chiedere che questi dati vengano modificati o addirittura cancellati. Ciò può essere richiesto attivamente dalla persona che lo desidera all’organo responsabile di cui sopra. In tale ottica è sufficiente un’e-mail.</p>
              <p><strong>7.  Link ad altri siti web</strong><br/>
              I nostri siti web e le nostre applicazioni contengono link a siti web gestiti e controllati da Würth AG. Il localizzatore unico della risorsa (Uniform Record Locator, URL) vi avvisa sempre se la pagina che state visitando è su uno dei nostri siti web. Vi raccomandiamo di consultare le dichiarazioni sulla protezione dei dati di questi siti web, poiché possono differire.</p>
              <p><strong>8.  Sicurezza e cifratura SSL-TLS</strong><br/>
              Per ragioni di sicurezza e per proteggere la trasmissione di contenuti confidenziali, come gli ordini o le richieste che inviate a noi, in veste di operatore del sito, questo sito utilizza la cifratura SSL o TLS. Si può riconoscere una connessione cifrata dalla linea dell’indirizzo del browser che passa da http:// a https:// e dal simbolo del lucchetto.</p>
              <p><strong>9.  Salvataggio dei vostri dati nei nostri sistemi, durata e backup dei dati</strong><br/>
              Per poter effettuare un’analisi statistica seria, i dati non riferiti a persone sono conservati fino a 1 anno. A seconda degli intervalli predefiniti, i dati (file di log) possono venir cancellati dall’hoster già dopo una sola settimana.</p>
              <p>Adottiamo misure organizzative e tecniche appropriate per proteggere i dati personali da manipolazione, perdita, distruzione o trattamento non autorizzato.</p>
              <p>Würth AG non si assume tuttavia responsabilità alcuna per danni derivanti dalla perdita di dati o dall’accesso non autorizzato ai dati personali e dal loro trattamento.</p>

            </div>

            <p>&nbsp;</p>

          </div>
        </div>
      </div>

    </transition-group>
</template>

<script>
import { Trans } from '../plugins/Translation.js'
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      displayMainView: true,
      displayMsgView: false
    }
  },
  computed: {
      locale () {
        return Trans.currentLocale
      }
    },
  methods: {

  }
}
</script>