<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-xl-6 offset-xl-3">
      <div class="c-boxed c-rounded c-shadow" data-background="white">
        <h1>{{ $t('checkout.title') }}</h1>

        <p v-if="$route.query.redirect">
          You need to login first.
        </p>

        <form v-on:submit.prevent="review">
        

          <fieldset>
            <legend>1. {{ $t('main.customerdata') }}</legend>
            <div class="form-group">
              <input 
                type="text" 
                :class="{ 'is-invalid' : errors.customername }"
                v-model="checkoutform.customername" 
                :placeholder="$t('main.customername')"
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.customername" class="invalid-feedback d-block">{{ errors.customername }}</small>
            </div>

            <div class="form-group">
              <input 
                type="text" 
                :class="{ 'is-invalid' : errors.email }"
                v-model="checkoutform.email" 
                :placeholder="$t('main.email')"
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.email" class="invalid-feedback d-block">{{ errors.email }}</small>
            </div>

            <div class="form-group">
              <input 
                type="text" 
                :class="{ 'is-invalid' : errors.carplate }"
                v-model="checkoutform.carplate" 
                :placeholder="$t('main.customercarplate')"
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.carplate" class="invalid-feedback d-block">{{ errors.carplate }}</small>
            </div>

            <div class="form-group">
              <!-- <input 
                type="date" 
                v-model="checkoutform.processingdate" 
                :class="{ 'is-invalid' : errors.processingdate }"
                :placeholder="$t('main.processingdate')"
                class="form-control" 
                autocomplete="off"> -->

              <VueCtkDateTimePicker 
                v-model="checkoutform.processingdate" 
                format="YYYY-MM-DD HH:mm" 
                :locale="locale" 
                color="#c00"
                :label="$t('main.selectdatetime')"
                buttonColor="#c00"
                :buttonNowTranslation="$t('main.now')"
              />

              <small v-if="errors.processingdate" class="invalid-feedback d-block">{{ errors.processingdate }}</small>
            </div>

            <div class="form-group">
              <textarea 
                :class="{ 'is-invalid' : errors.comments }"
                v-model="checkoutform.comments" 
                :placeholder="$t('main.comments')"
                class="form-control" 
                autocomplete="off">
              </textarea>
              <small v-if="errors.comments" class="invalid-feedback d-block">{{ errors.comments }}</small>
            </div>

          </fieldset>

          <p class="flex-set text-right btn-set">
            <button type="submit" class="btn btn-primary">{{ $t('cart.continuetoorder') }}</button>
          </p>

          <p v-if="msg">{{ msg }}</p>

        </form>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
 
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

export default {
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  data () {
    return {
      checkoutform : {email: "", customername: "", carplate: "", processingdate: this.currentDateTime(), comments: ""},
      errors: {},
      error: false,
      msg: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      order: {}
    }
  },
  methods: {
    review () {
      this.setting = true
      this.errors = {}

      let reqflds = ['customername','carplate','email','processingdate']
      for( let fld in reqflds) {
        if (this.checkoutform[reqflds[fld]] === '')
          this.errors[reqflds[fld]] = ' '
      }

      if (this.isEmailValid() !== '')
        this.errors.email = this.$t('error.msgs.invalidemail')

      if (Object.keys(this.errors).length !== 0)
        return false

      this.order.customeremail = this.checkoutform.email
      this.order.customername = this.checkoutform.customername
      this.order.customercarplate = this.checkoutform.carplate
      this.order.processingdate = this.checkoutform.processingdate
      this.order.comments = this.checkoutform.comments
      this.$store.commit("setOrder", this.order)
      this.$router.push({ path: 'checkoutreview' })

    },
    isEmailValid: function() {
      return (this.checkoutform.email == "") ? "" : (this.reg.test(this.checkoutform.email)) ? '' : 'is-invalid';
    },
    currentDateTime() {
      return moment(new Date(Date.now()).toISOString()).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
