<script>
  export default {
    name: 'Modal',
    props: {
    	order: {
    		type: Object,
    		default: {}
    	}
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
    
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-dialog modal-dialog-centered" data-size="lg" role="document">
			<div class="modal-content">
				<button type="button" class="close" data-dismiss="modal" @click="close">
					<i class="fal fa-times"></i>
				</button>
				<div class="modal-body">
					<slot name="header"><h1>ORDER DETAILS</h1></slot>
					<slot name="body"></slot>
					<p><button type="button" class="btn btn-primary" data-dismiss="modal" @click="close">SCHLIESSEN</button></p>
				</div>       
      		</div>
    	</div>
      </div>
    </div>
  </transition>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
</style>