<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
      <div class="c-boxed c-rounded c-shadow" data-background="white">
        <h1>{{ $t('menu.myaccount') }}</h1>

        <form v-on:submit.prevent="update">
          <fieldset>
            <legend>1. Würth Kundennummer</legend>
            <div class="form-group">
              <input type="text" :value="user.customernr" placeholder="Würth Kundennummer" class="form-control" autocomplete="off" readonly>
            </div>
          </fieldset>

          <fieldset>
            <legend>2. Kundendaten</legend>
            <div class="form-group">
              <input type="text" 
                :value="user.customername" 
                placeholder="Vorname / Name" 
                class="form-control" 
                autocomplete="off" 
                readonly>
            </div>
            <div class="form-group">
              <input type="text" :value="user.company" placeholder="Firma" class="form-control" autocomplete="off" readonly>
            </div>
            <div class="form-group">
              <input type="text" :value="user.address" 
                :placeholder="$t('main.address')" class="form-control" autocomplete="off" readonly>
            </div>
            <div class="form-group">
              <input type="text" :value="user.zipcity" placeholder="PLZ / Ort" class="form-control" autocomplete="off" readonly>
            </div>
            <div class="form-group">
              <input 
                type="text" 
                :class="{ 'is-invalid' : errors.phonenumber }"
                v-model="user.phonenumber" 
                :placeholder="$t('main.phonenumber')" 
                class="form-control"
                autocomplete="off">
              <small v-if="errors.phonenumber" class="invalid-feedback d-block">{{ errors.phonenumber }}</small>
            </div>
          </fieldset>

          <fieldset>
            <legend>3. Zugangsdaten</legend>
            <div class="form-group">
              <input type="text" :value="user.email" placeholder="E-Mail Adresse" class="form-control" autocomplete="off" readonly>
            </div>
            <div class="form-group">
              <input type="password" 
                :class="{ 'is-invalid' : errors.oldpass }"
                v-model="myaccform.oldpass" 
                placeholder="Aktuelles Passwort" 
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.oldpass" class="invalid-feedback d-block">{{ errors.oldpass }}</small>
            </div>
            <hr class="form-spacer">
            <div class="form-group">
              <input type="password" 
                :class="{ 'is-invalid' : errors.pass }"
                v-model="myaccform.pass" 
                placeholder="Neues Passwort" 
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.pass" class="invalid-feedback d-block">{{ errors.pass }}</small>
            </div>
            <div class="form-group">
              <input type="password" 
                :class="{ 'is-invalid' : errors.confirmpass }"
                v-model="myaccform.confirmpass" 
                placeholder="Neues Passwort wiederholen" 
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.confirmpass" class="invalid-feedback d-block">{{ errors.confirmpass }}</small>
            </div>
          </fieldset>

          <p class="flex-set text-right btn-set">
            <button type="submit" class="btn btn-primary">SPEICHERN</button>
          </p>

          <div v-if="error" class="invalid-feedback d-block"><p>{{ msg }}</p></div>
          <p v-if="!error && msg">{{ msg }}</p>

        </form>
      </div>

    </div>
  </div>  
</template>

<script>
import auth from '../auth.js'

export default {
  data () {
    return {
      errors: {},
      error: false,
      msg: "",
      myaccform: {oldpass: "", pass: "", confirmpass: "", email: ""}
    }
  },
  computed: {
    user () {
      return this.$store.getters.loggedInUser
    }
  },
  methods: {
    update () {
      this.setting = true
      this.errors = {}

      if (this.user.phonenumber === '')
        this.errors.phonenumber = ' '

      if (this.myaccform.oldpass != '' || this.myaccform.pass != '' || this.myaccform.confirmpass != '') {
        let reqflds = ['phonenumber','oldpass','pass','confirmpass']
        for( let fld in reqflds) {
          if (this.myaccform[reqflds[fld]] === '')
            this.errors[reqflds[fld]] = ' '
        }

        if (this.myaccform.pass !== this.myaccform.confirmpass)
          this.errors.confirmpass = this.$t('error.msgs.PASSWORD_MISMATCH')
      }

      if (Object.keys(this.errors).length !== 0)
      	return false

      let formData = new FormData();
      formData.append('email', this.user.email);
      formData.append('phonenumber', this.user.phonenumber);

      if (this.myaccform.oldpass != '' && this.myaccform.pass != '' && this.myaccform.confirmpass != '') {
        formData.append('oldpass', this.myaccform.oldpass);
        formData.append('pass', this.myaccform.pass);
        formData.append('confirmpass', this.myaccform.confirmpass);
      }

      axios.post('/cfc/remote/users.cfc?method=update', formData)
         .then((res) => {
            if(res.data.success) {
            	this.msg = this.$t('myaccount.successmsg')
              this.$store.commit('setLoggedInUser', res.data.user)
              this.error = false
            }
           	else {
           		this.error = true
              for (let er in res.data.errors) {
                this.msg = this.$t('error.msgs.'+res.data.errors[er])
              }
            }
         })
         .catch((error) => {
            this.error = true
         }).finally(() => {
            this.setting = false
         });

    }
  }
}
</script>