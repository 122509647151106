export default {
  "lang": "it",
  "back": "RESTITUZIONE",
  "passwordtip": "* min. 8 caratteri, 1 lettera minuscola, 1 lettera maiuscola, 1 numero, 1 carattere speciale",
  "menu": {
    "de": "Tedesco",
    "fr": "Francese",
    "it": "Italiano",
    "home": "Home",
    "login": "Login",
    "myaccount": "Il mio account",
    "settings": "Impostazioni",
    "orderhistory": "Cronologia ordini",
    "logout": "Disconnettersi",
    "forgotpassword": "password dimenticato",
    "resetpassword": "Resettare la password",
    "imprint": "Impressum",
    "dataprotection": "Politica sulla privacy"
  },
  "static": {
    "content": {
      "imprint": "Impressum",
      "dataprotection": "Politica sulla privacy"
    }
  },
  "main": {
    "title": "IL SISTEMA D'ORDINAZIONE PROFESSIONALE PER LA PREPARAZIONE DEI VEICOLI DEI VOSTRI CLIENTI",
    "button": "Registrati adesso",
    "content": "<p><b>PROFESSIONAL CAR CARE by Würth</b> offre ai propri partner un concetto di allestimento del veicolo pronto all’uso destinato ai clienti finali. L’app abbinata supporta il partner nell'elaborazione e nella vendita dei servizi di allestimento dei veicoli per i suoi clienti e semplifica notevolmente i processi al fine di conseguire in modo efficiente un fatturato aggiuntivo.</p><p>Interveniamo laddove il cliente ne trae vantaggio: nell’aspetto esteriore del suo veicolo. Un veicolo pulito e mantenuto in modo professionale non solo è esteticamente gradevole, ma assume anche un valore maggiore, il che in definitiva può aumentarne il valore di rivendita.</p><p>Abbiamo suscitato il vostro interesse? Contattateci su <a href='mailto:professionalcarcare@wuerth-ag.ch'>professionalcarcare@wuerth-ag.ch</a></p>",
    "new_message": "no messages | one message | {count} messages",
    "email": "Indirizzo e-mail",
    "fullname": "Nome / Cognome",
    "company": "Società",
    "zipcity": "Codice postale / Luogo",
    "phonenumber": "Telefono",
    "address": "Indirizzo",
    "password": "Password",
    "confirmpassword": "Ripetere la password",
    "customernumber": "Numero cliente",
    "customerdata": "Dati cliente",
    "customername": "Nome cliente",
    "customercarplate": "Targa",
    "accessdata": "Dati accesso",
    "packageslist": "Pacchetti di pulizia",
    "legitimation": "Legittimazione",
    "save": "SALVARE",
    "chooseor": "o",
    "backtoselection": "TORNA ALLA SELEZIONE",
    "processingdate": "Data di esecuzione",
    "comments": "Osservazioni",
    "selectdatetime": "Seleziona data e ora",
    "now": "Adesso",
    "order": "Ordine"
  },
  "login": {
    "title": "",
    "welcome": "Effettuare il login con i vostri dati di accesso:",
    "requiredlogin": "Deve prima effettuare il login."
  },
  "register": {
    "title": "REGISTRAZIONE",
    "button": "CONTINUA",
    "successmsg": "Utente creato con successo. Una volta attivato il vostro account, ricevera una notifica via email."
  },
  "forgotpassword": {
    "title": "RESETTA LA PASSWORD",
    "linktologin": "Al login",
    "successmsg": "Un link per reimpostare la password è stato inviato alla vostra email."
  },
  "resetpassword": {
    "successmsg": "La vostra password è stata resettata."
  },
  "myaccount": {
    "successmsg": "Utente aggiornato con successo."
  },
  "settings": {
    "hourlyrate": "Tariffa oraria",
    "backgroundimg": "Immagine di sfondo",
    "choosefile": "Scegli un file",
    "successmsg": "Utente aggiornato con successo.",
    "clearimage": "Immagine chiara",
    "availablepackages": "Servizi"
  },
  "package": {
    "title": "Servizi a colpo d'occhio",
    "selectvehiclesize": "Seleziona la dimensione del veicolo:",
    "addedtocart": "Il prodotto è stato<br>aggiunto al carrello",
    "addtocart": "NEL CARRELLO",
    "mwsttext": "Prezzi IVA esclusa"
  },
  "cart": {
    "title": "Carrello",
    "emptycart": "Il vostro carello è vuoto",
    "continuetoorder": "Continua l'ordine",
    "mwsttext": "Prezzi IVA esclusa"
  },
  "checkout": {
     "title": "Informazioni sul vostro cliente"
  },
  "checkoutreview": {
    "title": "PANORAMICA ORDINE",
    "button": "CONFERMARE DELL'ORDINE"
  },
  "checkoutsubmit": {
    "title": "Ordine completato",
    "thankyou": "<p>Grazie per il vostro ordine!<br> <br>Ricevera una conferma d'ordine via e-mail.</p>"
  },
  "orderhistory": {
  	"orderdate": "Data",
  	"ordernumber": "Ordine"
  },
  "error": {
    "msgs": {
      "isrequired": "{fld} è obbligatorio",
      "invalidemail": "Indirizzo email non valido",
      "invalidcustomernumber": "Numero cliente non valido.",
      "badlogininformation": "Informazioni di accesso errate",
      "PASSWORD_INSECURE": "La password deve contenere almeno una lettera maiuscola, una lettera minuscola, un numero, 1 carattere speciale e un minimo di 8 caratteri.",
      "EMAIL_ALREADY_REGISTERED": "E-mail già registrata",
      "EMAIL_INVALID": "Indirizzo e-mail non valido",
      "PASSWORD_MISMATCH": "Mancata corrispondenza dei campi della password.",
      "INCORRECT_PASSWORD": "Password errata",
      "KEY_INVALID": "Vostro login è scaduto. ",
      "USER_NOT_ACTIVATED": "UTENTE NON ATTIVATO",
      "USER_NOT_FOUND": "L'utente non è stato trovato."
    }
  }
  
}
