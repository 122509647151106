<template>
  <transition-group name="fade">
    <div class="row" v-if="displayMainView" key="mainview">
      <div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
        <div class="c-boxed c-rounded c-shadow" data-background="white">
          <h1>{{ $t('register.title') }}</h1>

          <p v-if="$route.query.redirect">
            You need to login first.
          </p>

          <form v-on:submit.prevent="register">
          	
            <fieldset>
              <legend>1. {{ $t('main.legitimation') }}</legend>
              <div class="form-group">
                <input type="text" 
                	:class="{ 'is-invalid' : errors.customernumber }"
                	v-model="regform.customernumber" 
                	:placeholder="'Würth '+ $t('main.customernumber')" 
                	class="form-control" 
                	autocomplete="off" maxlength="9">
                <small v-if="errors.customernumber" class="invalid-feedback d-block">{{ errors.customernumber }}</small>
              </div>
            </fieldset>

            <fieldset>
              <legend>2. {{ $t('main.customerdata') }}</legend>
              <div class="form-group">
                <input type="text" 
                	:class="{ 'is-invalid' : errors.customername }"
                	v-model="regform.customername" 
                	:placeholder="$t('main.fullname')" 
                	class="form-control" 
                	autocomplete="off">
                <small v-if="errors.customername" class="invalid-feedback d-block">{{ errors.customername }}</small>
              </div>
              <!-- <div class="form-group">
                <input type="text" 
                  :class="{ 'is-invalid' : errors.hourlyrate }"
                  v-model="regform.hourlyrate" 
                  placeholder="Stundenansatz" 
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.hourlyrate" class="invalid-feedback d-block">{{ errors.hourlyrate }}</small>
              </div> -->
              <div class="form-group">
                <input type="text" 
                	:class="{ 'is-invalid' : errors.company }"
                	v-model="regform.company" 
                	:placeholder="$t('main.company')" 
                	class="form-control" 
                	autocomplete="off">
                <small v-if="errors.company" class="invalid-feedback d-block">{{ errors.company }}</small>
              </div>

              <div class="form-group">
                <input 
                  type="text" 
                  :class="{ 'is-invalid' : errors.address }"
                  v-model="regform.address" 
                  :placeholder="$t('main.address')" 
                  class="form-control"
                  autocomplete="off">
                <small v-if="errors.address" class="invalid-feedback d-block">{{ errors.address }}</small>
              </div>

              <div class="form-group">
                <input 
                	type="text" 
                	:class="{ 'is-invalid' : errors.zipcity }"
                	v-model="regform.zipcity" 
                	:placeholder="$t('main.zipcity')" 
                	class="form-control"
                	autocomplete="off">
                <small v-if="errors.zipcity" class="invalid-feedback d-block">{{ errors.zipcity }}</small>
              </div>

              <div class="form-group">
                <input 
                  type="text" 
                  :class="{ 'is-invalid' : errors.phonenumber }"
                  v-model="regform.phonenumber" 
                  :placeholder="$t('main.phonenumber')" 
                  class="form-control"
                  autocomplete="off">
                <small v-if="errors.phonenumber" class="invalid-feedback d-block">{{ errors.phonenumber }}</small>
              </div>
            </fieldset>

            <fieldset>
              <legend>3. {{ $t('main.accessdata') }}</legend>
              <div class="form-group">
                <input 
                	type="text" 
                	:class="{ 'is-invalid' : errors.email }"
                	v-model="regform.email" 
                	:placeholder="$t('main.email')" 
                	class="form-control" 
                	autocomplete="off">
                <small v-if="errors.email" class="invalid-feedback d-block">{{ errors.email }}</small>
              </div>
              <div class="form-group">
                <input 
                	type="password" 
                	:class="{ 'is-invalid' : errors.pass }"
                	v-model="regform.pass" 
                	:placeholder="$t('main.password')"
                	class="form-control" 
                	autocomplete="off">
                <small v-if="errors.pass" class="invalid-feedback d-block">{{ errors.pass }}</small>
              </div>
              <div class="form-group">
                <input 
                	type="password" 
                	:class="{ 'is-invalid' : errors.confirmpass }"
                	v-model="regform.confirmpass" 
                  :placeholder="$t('main.confirmpassword')"
                	class="form-control" 
                	autocomplete="off">
                <small v-if="errors.confirmpass" class="invalid-feedback d-block">{{ errors.confirmpass }}</small>
              </div>

            </fieldset>
            <small class="mt-2">{{ $t('passwordtip') }}</small>

            <p class="flex-set text-right btn-set">
              <button type="submit" class="btn btn-primary">{{ $t('register.button') }}</button>
            </p>
            <!-- <p v-if="error" class="error invalid-feedback" style="display: block">Bad user information</p> -->

            <p v-if="msg">{{ msg }}</p>

          </form>
        </div> <!--- /.c-boxed --->

      </div>
    </div>

    <div class="row" v-if="displayMsgView" key="msgview">
      <div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
        <div class="c-boxed c-rounded c-shadow" data-background="white">

          <p v-if="msg">{{ msg }}</p>

        </div>
      </div>
    </div>

  </transition-group>
</template>

<script>
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'

export default {
  data () {
    return {
      regform : {customernumber : "", customername : "", company: "", address: "", zipcity: "", phonenumber: "", email: "", hourlyrate: "100", pass: "", confirmpass: ""},
      errors: {},
      error: false,
      msg: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      displayMainView:true,
      displayMsgView:false
    }
  },
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  methods: {
    isRequiredMsg (fld) {
      this.errors[fld] = this.$t('error.msgs.isrequired', {fld: '"'+this.$t('main.'+fld) + '"' })
    },
    register () {
      this.setting = true
      this.errors = {}

      let reqflds = ['customernumber','customername','company','address','zipcity','phonenumber','email','pass','confirmpass']
      for( let fld in reqflds) {
        if (this.regform[reqflds[fld]] === '')
          this.errors[reqflds[fld]] = ' '
      }

      if (this.regform.customernumber.length && (this.regform.customernumber.substr(0, 2) !== '36' || this.regform.customernumber.length != 9))
        this.errors.customernumber = this.$t('error.msgs.invalidcustomernumber')
   	  if (this.isEmailValid() !== '')
        this.errors.email = this.$t('error.msgs.invalidemail')
      if (this.regform.pass !== this.regform.confirmpass)
        this.errors.confirmpass = this.$t('error.msgs.PASSWORD_MISMATCH')

      if (Object.keys(this.errors).length !== 0)
      	return false

      this.regform.locale = this.locale
      axios.post('/cfc/remote/users.cfc?method=register', this.regform)
         .then((res) => {
            if(res.data.success) {
            	this.msg = this.$t('register.successmsg')
              this.displayMainView = false
              this.displayMsgView = true
            }
           	else {
           		this.error = true
              this.msg = this.$t('error.msgs.'+res.data.error)
            }
         })
         .catch((error) => {
            // error.response.status Check status code
            this.error = true
         }).finally(() => {
            //Perform action in always
            this.setting = false
         });

    },
    isEmailValid: function() {
      return (this.regform.email == "") ? "" : (this.reg.test(this.regform.email)) ? '' : 'is-invalid';
    }

  }
}
</script>