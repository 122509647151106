import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixin from './mixins'
import { i18n } from './plugins/i18n.js'
import MainApp from "./views/mainapp.vue"


Vue.config.productionTip = true

new Vue({
  i18n,
  mixins: [mixin],
  router,
  store,
  components: {
	'main-app': MainApp
  },
  render: h => h(App)
}).$mount('#app')

console.log('NODE_ENV = '+process.env.NODE_ENV)
