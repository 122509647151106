<template>
  <div class="row row-50px">
	<div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
		<div class="c-boxed c-rounded c-shadow" data-background="white">
			<h1>{{ $t('cart.title') }}</h1>

			<fieldset>
				<legend>{{ $t('main.packageslist') }}</legend>

				<div class="basket-products" v-if="$store.state.cart.length > 0">
					<table cellpadding="0" cellspacing="0">
						<tbody>
							<tr v-for="cartitem in $store.state.cart">
								<td>
									<span>{{ cartitem.section }}</span>
									<strong>{{ cartitem.name }} - {{ cartitem.cartypename }}</strong>
								</td>
								<td>
									<em>{{ formatPrice(cartitem.price) }}</em>
								</td>
								<td>
									<a class="btn btn-primary" @click.prevent="removeFromCart(cartitem)">
										<i class="fal fa-minus"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="basket-price">
						<h2>{{ formatPrice(totalPrice) }}</h2>
						<p class="flex-set text-right btn-set mb-2">
							<router-link :to="$i18nRoute({ name: 'Checkout' })" class="btn btn-lg btn-primary">{{ $t('cart.continuetoorder') }}</router-link>
						</p>
						<small class="d-block">{{ $t('cart.mwsttext') }}</small>
					</div> 
				</div>

				<div v-else class="navbar-dropdown is-boxed is-right">
		            <a class="navbar-item" href="">
		                {{ $t('cart.emptycart') }}
		            </a>
		        </div>

			</fieldset>

		</div>

	</div>
  </div>
</template>

<script>
import auth from '../auth.js'
export default {
  computed: {
	totalPrice() {
        let total = 0;
        for (let item of this.$store.state.cart) {
            total += parseFloat(item.totalPrice);
        }
        return total.toFixed(2);
    }
  },
  methods: {
  	formatPrice(value) {
      let val = (value/1).toFixed(2)
      let newval = ' '+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      let currsign ='CHF'        
      return currsign.concat(newval)
  	},
  	removeFromCart(item) {
        this.$store.commit('removeFromCart', item);
    }
  }
}
</script>