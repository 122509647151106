<template>
  <div class="content-inner app-spacing">
    <div class="row">
      <div class="col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
        <div class="c-boxed c-rounded c-shadow" data-background="white">
          <h1>{{ $t('menu.resetpassword') }}</h1>

          <form v-on:submit.prevent="resetpassword">
            <div class="form-group-set">
              <div class="form-group">
                <input 
                  type="password" 
                  :class="{ 'is-invalid' : errors.pass }"
                  v-model="frm.pass" 
                  :placeholder="$t('main.password')"
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.pass" class="invalid-feedback d-block">{{ errors.pass }}</small>
              </div>
              <div class="form-group">
                <input 
                  type="password" 
                  :class="{ 'is-invalid' : errors.confirmpass }"
                  v-model="frm.confirmpass" 
                  :placeholder="$t('main.confirmpassword')"
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.confirmpass" class="invalid-feedback d-block">{{ errors.confirmpass }}</small>
              </div>
            </div>
            <small class="mt-2">{{ $t('passwordtip') }}</small>

            <p class="flex-set text-right">
              <router-link :to="$i18nRoute({ name: 'Login' })" v-if="continueToLogin">{{ $t('forgotpassword.linktologin') }}</router-link>
              <button type="submit" class="btn btn-primary" :disabled="resetBtnDisabled">{{ $t('main.save') }}</button>
            </p>

            <p v-if="msg">{{ msg }}</p>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../auth'

export default {
  data () {
    return {
      errors: {},
      frm: {pass: '', confirmpass: ''},
      error: false,
      msg: "",
      continueToLogin: false,
      resetBtnDisabled: false
    }
  },
  methods: {
    resetpassword () {

      this.errors = {}

      let reqflds = ['pass','confirmpass']
      for( let fld in reqflds) {
        if (this.frm[reqflds[fld]] === '')
          this.errors[reqflds[fld]] = ' '
      }
      if (this.frm.pass !== this.frm.confirmpass)
        this.errors.confirmpass = this.$t('error.msgs.PASSWORD_MISMATCH')

      if (Object.keys(this.errors).length !== 0)
        return false

      if (this.$route.query.key == undefined || this.$route.query.key === '') {
        this.error = true
        this.msg = "Key expired"
        return false
      }

      this.frm.key = this.$route.query.key

      axios.post('/cfc/remote/users.cfc?method=resetpassword', this.frm)
         .then((res) => {
            if(res.data.success) {
              this.msg = this.$t('resetpassword.successmsg')
              this.continueToLogin = true
              this.resetBtnDisabled = true
            }
            else {
              this.error = true
              this.msg = this.$t('error.msgs.'+res.data.error)
            }
         })
         .catch((error) => {
            // error.response.status Check status code
            this.error = true
         }).finally(() => {
            //Perform action in always
            this.setting = false
         });
      
    }
  }
}
</script>