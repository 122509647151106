<template>
	<transition-group name="fade">
	
    <div class="row" v-if="!loggedIn" key="main">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

        <div class="c-boxed c-rounded c-shadow" data-background="white">

          <h1>{{ $t('main.title') }}</h1>

          <div v-html="$t('main.content')"></div>

          <p class="flex-set text-right btn-set">
            <router-link v-if="!loggedIn" class="btn btn-lg btn-primary" :to="$i18nRoute({ name: 'Register' })">{{ $t('main.button') }}</router-link>
          </p>

        </div>
      </div>
    </div>  

    <section-list v-if="loggedIn" key="inonly"></section-list>  

  </transition-group>
</template>

<script>
import auth from '../auth'
import SectionList from "./sectionlist.vue"
import { Trans } from '../plugins/Translation.js'

export default {
  components: {
  	'section-list': SectionList
  },
  data() {
    return {
      loggedIn: auth.loggedIn()
    }
  },
  created () {
    auth.onChange = loggedIn => {
      this.loggedIn = loggedIn
    }
  },
  computed: {
      locale () {
        //return store.getters.locale
        return Trans.currentLocale
      }
    },
  methods: {

  }
}
</script>