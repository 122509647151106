<template>
  <div id="cat-menu" class="products-overview" current-level="2">
    
    <div class="cat-breadcrumb">
      <div class="breadcrumb-elements d-block">
        <router-link :to="$i18nRoute({ name: 'Home' })" class="back">
          <i class="far fa-arrow-left"></i> 
          <label>{{ section.name }}</label>
        </router-link> 
        <router-link :to="$i18nRoute({ name: 'Home' })" class="btn btn-primary btn-reset">ZURÜCK</router-link>
      </div>
    </div>

    <div class="row row-50px">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6">

        <div class="menu-set">
            
            <div id="menu-level-2" class="menu-change" style="">
              <ul>
                <li v-for="articlepackage, index in section.packages">
                  <router-link :to="$i18nRoute({ name: 'Package', params: { id: articlepackage.id } })" class="btn btn-primary"><span>Paket {{ index+1 }} - {{ articlepackage.name }}</span></router-link>
                </li>
              </ul>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Trans } from '../plugins/Translation.js'

export default {
  data() {
    return {
      section: {}
    }
  },
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  methods: {
    getSection() {
      this.loading = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/API/section/${this.$route.params.id}/${this.locale}/`
      })
      .then(response => {
        this.section = response.data[0]
        this.loading = false
      })
      .catch(error => {
        console.log(error)
        if (error.response.status) {
          delete localStorage.token
          delete localStorage.loggedInUser
          window.location.href = "/" + this.locale + "/"
        }
        this.loading = false
      })
    }
  },
  mounted() {
    this.getSection()
  },
  watch: {
    locale: function() {
       this.getSection()
    }
  }
}
</script>