export default {
  "lang": "de",
  "back": "ZURÜCK",
  "passwordtip": "Mind. 8 Zeichen, 1 Kleinbuchstaben, 1 Grossbuchstaben, 1 Zahl, 1 Sonderzeichen",
  "menu": {
    "de": "Deutsch",
    "fr": "Französisch",
    "it": "Italienisch",
    "home": "Home",
    "login": "LOGIN",
    "myaccount": "Mein Konto",
    "settings": "Einstellungen",
    "orderhistory": "Bestellverlauf",
    "logout": "Abmelden",
    "forgotpassword": "Passwort vergessen",
    "resetpassword": "Passwort zurücksetzen",
    "imprint": "Impressum",
    "dataprotection": "Datenschutzerklärung"
  },
  "static": {
    "content": {
      "imprint": "IMPRESSUM TEXT IN GERMAN",
      "dataprotection": "DATENSCHUTZ TEXT IN GERMAN"
    }
  },
  "main": {
    "title": "DAS PROFESSIONELLE BESTELLSYSTEM FÜR DIE FAHRZEUGAUFBEREITUNG IHRER KUNDENFAHRZEUGE",
    "button": "JETZT REGISTRIEREN",
    "content": "<p><b>PROFESSIONAL CAR CARE by Würth</b> bietet seinen Partnerbetrieben ein fixfertiges Konzept zur Fahrzeugaufbereitung für ihre Endkunden. Die dazugehörige App unterstützt den Partnerbetrieb bei der Abwicklung und dem Vertrieb von Dienstleistungen bei der Fahrzeugaufbereitung für seine Kunden und vereinfacht die Abläufe wesentlich, sodass der gewünschte Mehrumsatz effizient erreicht werden kann. </p><p>Wir setzen dort an, wo der Kunde etwas davon hat: beim Erscheinungsbild seines Fahrzeuges. Ein professionell gereinigtes und gepflegtes Fahrzeug sieht nicht nur gut aus, sondern wirkt dadurch hochwertiger, was schlussendlich auch den Wiederverkaufswert erhöhen kann.</p><p>Haben wir Ihr Interesse geweckt? Kontaktieren Sie uns unter <a href='mailto:professionalcarcare@wuerth-ag.ch'>professionalcarcare@wuerth-ag.ch</a></p>",
    "new_message": "no messages | one message | {count} messages",
    "email": "E-Mail Adresse",
    "fullname": "Vorname / Name",
    "company": "Firma",
    "zipcity": "PLZ / Ort",
    "phonenumber": "Telefon",
    "address": "Adresse",
    "password": "Passwort",
    "confirmpassword": "Passwort wiederholen",
    "customernumber": "Kundennummer",
    "customerdata": "Kundendaten",
    "customername": "Kundenname",
    "customercarplate": "Kontrollschild",
    "accessdata": "Zugangsdaten",
    "packageslist": "Reinigungspakete",
    "legitimation": "Legitimation",
    "save": "SPEICHERN",
    "chooseor": "oder",
    "backtoselection": "ZURÜCK ZUR AUSWAHL",
    "processingdate": "Ausführungsdatum",
    "comments": "Bemerkungen",
    "selectdatetime": "Datum & Uhrzeit auswählen",
    "now": "Jetzt",
    "order": "Bestellen"
  },
  "login": {
    "welcome": "Bitte melden Sie sich mit Ihren Zugangsdaten an:",
    "requiredlogin": "Sie müssen sich zuerst anmelden."
  },
  "register": {
    "title": "REGISTRIERUNG",
    "button": "WEITER",
    "successmsg": "Benutzer erfolgreich erstellt. Sobald Ihr Konto aktiviert ist, werden Sie per E-Mail benachrichtigt."
  },
  "forgotpassword": {
  	"title": "PASSWORT ZURÜCKSETZEN",
  	"linktologin": "Zum Login",
  	"successmsg": "Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet."
  },
  "resetpassword": {
    "successmsg": "Ihr Passwort wurde zurückgesetzt."
  },
  "myaccount": {
    "successmsg": "Benutzer erfolgreich aktualisiert."
  },
  "settings": {
    "hourlyrate": "Stundensatz",
    "backgroundimg": "Hintergrundbild",
    "choosefile": "Datei auswählen",
    "successmsg": "Benutzer erfolgreich aktualisiert.",
    "clearimage": "Klares Bild",
    "availablepackages": "Dienstleistungen"
  },
  "package": {
    "title": "Leistungen im Überblick",
    "selectvehiclesize": "Fahrzeuggrösse wählen:",
    "addedtocart": "Das Produkt wurde in<br>den Warenkorb gelegt",
    "addtocart": "IN DEN WARENKORB",
    "mwsttext": "Preise exkl. Mehrwertsteuer"
  },
  "cart": {
    "title": "Warenkorb",
    "emptycart": "Ihr Korb ist leer",
    "continuetoorder": "Bestellung fortsetzen",
    "mwsttext": "Preise exkl. Mehrwertsteuer"
  },
  "checkout": {
    "title": "Angaben zu Ihrem Kunden"
  },
  "checkoutreview": {
    "title": "BESTELLÜBERSICHT",
    "button": "BESTELLUNG ABSCHLIESSEN"
  },
  "checkoutsubmit": {
    "title": "Bestellung abgeschlossen",
    "thankyou": "<p>Vielen Dank für Ihre Bestellung!<br><br>Sie erhalten eine Auftragsbestätigung per E-Mail.</p>"
  },
  "orderhistory": {
  	"orderdate": "Auftragsdatum",
  	"ordernumber": "Bestellnummer"
  },
  "error": {
    "msgs": {
      "isrequired": "{fld} ist erforderlich",
      "invalidemail": "Ungültige E-Mail-Adresse",      
      "invalidcustomernumber": "Ungültige Kundennummer.",
      "badlogininformation": "Falsche Login-Informationen",
      "PASSWORD_INSECURE": "Das Passwort sollte mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl, ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein.",
      "EMAIL_ALREADY_REGISTERED": "E-Mail schon registriert",
      "EMAIL_INVALID": "Ungültige E-Mail-Adresse",
      "PASSWORD_MISMATCH": "Passwortfelder stimmen nicht überein.",
      "INCORRECT_PASSWORD": "Falsches Passwort",
      "KEY_INVALID": "Link ist abgelaufen. ",
      "USER_NOT_ACTIVATED": "Benutzer nicht aktiviert",
      "USER_NOT_FOUND": "Benutzer wurde nicht gefunden."
    }
  }
}