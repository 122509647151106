/* globals localStorage */
import { Trans } from './plugins/Translation.js'
import store from './store'

export default {
  login (email, pass, cb) {
    cb = arguments[arguments.length - 1]
    if (localStorage.token) {
      if (cb) cb(true)
      this.onChange(true)
      return
    }
    loginRequest(email, pass, (res) => {
      if (res.authenticated) {

        let redirectto = "/" + Trans.currentLocale + "/"

        localStorage.token = res.token
        if (cb) cb(true)
        //this.onChange(true)
    	  window.location.href =redirectto
      } else {
        if (cb) cb(false)
        //this.onChange(false)
      }
    })
  },

  getToken () {
    return localStorage.token
  },

  logout (cb) {
    let redirectto = "/" + Trans.currentLocale + "/"
    delete localStorage.token
    delete localStorage.loggedInUser
    delete localStorage.cartype
    if (cb) cb()
    this.onChange(false)
    axios.get(process.env.VUE_APP_API_LOGOUT_URL, {})
    .then(response => {
	    window.location.href = redirectto
    })
    .catch(error => {});
  },

  loggedIn () {
    return !!localStorage.token
  },

  onChange () {
    
  }
}

function loginRequest (email, pass, cb) {

  let formData = new FormData();
  formData.append('email', email);
  formData.append('pass', pass);
  axios.post(process.env.VUE_APP_API_LOGIN_URL, formData,
    {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
      if(response.data.success == true) {
        
        store.commit('setLoggedInUser', response.data.loggedInUser)

        cb({
          authenticated: true,
          token: Math.random().toString(36).substring(7)
        })
      }
      else
        cb({ authenticated: false })
    })
    .catch(error => {
      cb({ authenticated: false })
    })
}
