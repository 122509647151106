let cart = window.localStorage.getItem('cart')
let cartCount = window.localStorage.getItem('cartCount')
let loggedInUser = window.localStorage.getItem('loggedInUser')
let order = window.localStorage.getItem('order')
let cartype = window.localStorage.getItem('cartype')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		count: 0,
        cart: cart ? JSON.parse(cart) : [],
    	cartCount: cartCount ? parseInt(cartCount) : 0,
    	loggedInUser: loggedInUser ? JSON.parse(loggedInUser) : {},
    	dropPackage: false,
    	order: order ? JSON.parse(order) : {},
    	cartype: cartype ? cartype : 0
	},
	mutations: {
		increment: state => state.count++,
		decrement: state => state.count--,
		addToCart(state, item) {

		  let found = state.cart.find(obj => obj.id == item.id && obj.cartype == item.cartype && obj.price == item.price);
		    
		  if (!found) {
		    state.cart.push(item);

		    Vue.set(item, 'quantity', 1);
		    Vue.set(item, 'totalPrice', item.price);
		    state.cartCount++;

			this.commit('saveCart');
			this.commit('setDropPackage',true)
		  }
		},
		removeFromCart(state, item) {
		    let index = state.cart.indexOf(item);
		    if (index > -1) {
		        let articlepackage = state.cart[index];
		        state.cartCount -= articlepackage.quantity;
		        state.cart.splice(index, 1);
		        this.commit('saveCart');
		    }
		},
		clearCart(state) {
		    state.cart = []
		    state.cartCount = 0
		    this.commit('saveCart')
		},
		saveCart(state) {
		    window.localStorage.setItem('cart', JSON.stringify(state.cart));
		    window.localStorage.setItem('cartCount', state.cartCount);
		},
		setLoggedInUser (state, user) {
            state.loggedInUser = user
            window.localStorage.setItem('loggedInUser', JSON.stringify(state.loggedInUser));
        },
        setDropPackage(state) {
        	state.dropPackage = true
        },
        setOrder(state, order) {
            state.order = order
            window.localStorage.setItem('order', JSON.stringify(state.order));
        },
        setCarType(state, cartype) {
        	state.cartype = cartype
        	window.localStorage.setItem('cartype', state.cartype);
        },
	},
	getters: {
		loggedInUser: state => {
            return state.loggedInUser
        },
        order: state => {
            return state.order
        }
	}
})