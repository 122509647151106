<template>
  <div id="cat-menu" class="products-overview" current-level="1">
    <div class="cat-breadcrumb">
      <div class="breadcrumb-elements">
        <a class="back">
          <i class="far fa-arrow-left"></i>
          <label></label>
        </a>
        <button class="btn btn-primary btn-reset">{{ $t('back') }}</button>
      </div>
    </div>

    <div class="row row-50px">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6">

        <div class="menu-set">
          <div id="menu-level-1" class="menu-change">
            <ul data-level="1">
              <li class="nav-dropdown" data-level="1" v-for="section, index in sections">
                <a>
                  <span>{{ section.name }}</span>
                </a>
                <ul>
                  <li v-for="articlepackage, index in section.packages">
                    <router-link :to="$i18nRoute({ name: 'Package', params: { id: articlepackage.id } })" class="btn btn-primary"><span>{{ articlepackage.name }}</span></router-link>
                  </li>

                </ul>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Trans } from '../plugins/Translation.js'

export default {
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  mounted() {
    this.getSections()
  },
  data() {
    return {
      sections: []
    }
  },
  methods: {
    getSections() {
      this.loading = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/API/usersections/${this.locale}/`
      })
      .then(response => {
        this.sections = response.data;
        this.loading = false
      })
      .catch(error => {
        if (error.response.status) {
          delete localStorage.token
          delete localStorage.loggedInUser
          window.location.href = "/" + this.locale + "/"
        }
        this.loading = false
      })
    }
  },
  watch: {
    locale: function() {
       this.getSections()
    }
  }
}
</script>