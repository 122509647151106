<template>
  <div id="cat-menu" class="products-detail">
              
    <div class="cat-breadcrumb">
      <div class="breadcrumb-elements">

        <router-link :to="$i18nRoute({ name: 'Section', params: { id: articlepackage.sectionid } })" class="back">
          <i class="far fa-arrow-left"></i>
          <label>{{ articlepackage.section }}<span>-</span><em>{{ articlepackage.name }}</em></label>
        </router-link>
        
        <router-link :to="$i18nRoute({ name: 'Home' })" class="btn btn-primary btn-reset">
          {{ $t('main.backtoselection') }}
        </router-link>
      </div>
    </div>

    <div class="row row-50px">
      
      <div class="col-12 col-sm-12 col-md-12 col-lg-6">
        <div class="c-boxed c-rounded no-rounded-bottom c-shadow" data-background="white">
          <h4>{{ articlepackage.section }}</h4>
          <h2>{{ articlepackage.name }}</h2>

          <div class="c-basket-form" v-if="!loading">
            <div class="form-group">
              <label>{{ $t('package.selectvehiclesize') }}</label>
              <select class="form-control custom-select" v-model="cartype_optsel" @change="onChange($event)">
                <option v-for="cartype_opt in cartype_opts" 
                  v-bind:value="cartype_opt.typeid"
                  >{{ cartype_opt.typename }}</option>
              </select>
            </div>
            
            <hr class="basket-line" />
            <div class="basket-price">
              <div class="row row-10px">
                <div class="col-12 col-sm-6" data-col="price">
                  <div class="form-group form-with-chf">
                    <label for="form_price">CHF</label>
                    <input id="form_price" type="text" v-model="articlepackage.price" data-price-form class="form-control">
                  </div>
                </div>
                
                <div class="col-12 col-sm-6" data-col="button">
                  <button @click="addToCart(articlepackage)" class="btn btn-lg btn-responsive btn-primary" :disabled="isDisabled">
                    <span>{{ $t('package.addtocart') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <small class="d-block">{{ $t('package.mwsttext') }}</small>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-sm-12 col-md-12 col-lg-6">
        <div class="c-boxed c-rounded no-rounded-bottom c-shadow" data-background="white">
          
          <p>{{ articlepackage.desc }}</p>

          <h5>{{ $t('package.title') }}</h5>
          <div class="c-box-accordion last-rounded-bottom" data-toggle="true" data-animate="false"> <!---  data-toggle="true/false"  |  data-animate="true/false"  -->
            <div class="accordion-set">

              <div class="accordion-tab" data-status="closed" v-for="step, index in articlepackage.steps">
                <div class="accordion-title">
                  <a>{{ step.order }}. {{ step.name }}</a>
                </div>
                <div class="accordion-content">
                  <div v-html="step.desc"></div>
                  <img v-if="step.img" :src="'/files/packagestep-images/large/'+step.img" class="img-fluid mb-4" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import auth from '../auth'
import { Trans } from '../plugins/Translation.js'

export default {
  data () {
    return {
      articlepackage: {},
      cartypes: [],
      cartype_optsel: 1,
      cartype_opts: [
        {
          "typeid": 1,
          "typename": "Kleinwagen",
          "price": ""
        }
      ],
      loading: true,
      error: false
    }
  },
  computed: {
    locale () {
      return Trans.currentLocale
    },
    isDisabled: function(){
      return this.packageInCart(this.articlepackage)
    }
  },
  methods: {
    onChange(event) {
      this.articlepackage.cartype = this.cartype_optsel
      this.articlepackage.cartypename = this.getCarTypeName().toString()
      this.articlepackage.price = parseFloat(this.getArticlePackagePrice()).toFixed(2)
      this.$store.commit('setCarType',this.articlepackage.cartype)

    },
    getArticlePackage() {
      this.loading = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/API/package/${this.$route.params.id}/${this.locale}/`
      })
      .then(response => {
        this.articlepackage = response.data.package

        if(this.$store.state.cartype != 0) {
          this.cartype_optsel = this.$store.state.cartype
        }
        else {
          this.cartype_optsel = this.articlepackage.prices[0].typeid
        }
        
        this.articlepackage.cartype = this.cartype_optsel
        this.articlepackage.cartypename = this.getCarTypeName().toString()
        this.articlepackage.price = parseFloat(this.getArticlePackagePrice()).toFixed(2)
        this.cartype_opts = this.articlepackage.prices

        this.loading = false
      })
      .catch(error => {
        console.log(error)
        if (error.response.status) {
          delete localStorage.token
          delete localStorage.loggedInUser
          window.location.href = "/" + this.locale + "/"
        }
        this.loading = false
      })
    },
    getArticlePackagePrice() {
      return this.articlepackage.prices.filter(e => {
        if (e.typeid == this.articlepackage.cartype) {
          return e
        }
      }).map(f => f.price)
    },
    getCarTypeName() {
      return this.articlepackage.prices.filter(e => {
        if (e.typeid == this.articlepackage.cartype) {
          return e
        }
      }).map(f => f.typename)
    },
    addToCart(item) {
      this.$store.commit('addToCart', item);
    },
    packageInCart(item) {
        let found = this.$store.state.cart.find(obj => obj.id == item.id)
        if (found) {
            return true 
        }
        return false
    },
    packageIdInCart(pid) {
        let found = this.$store.state.cart.find(obj => obj.id == pid)
        if (found) {
            this.$store.commit('setDropPackage',true)
            return true 
        }
        return false
    }
},
  mounted() {
    this.getArticlePackage()
  },
  watch: {
    locale: function() {
       this.getArticlePackage()
    }
  }
}
</script>