<template>
	<transition-group name="fade">
	
    <div class="row" key="main">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

        <div class="c-boxed c-rounded c-shadow" data-background="white">

          <h1>{{ $t('menu.imprint') }}</h1>

          <!-- <div v-html="$t('static.content.'+this.content)"></div> -->

          <div v-if="locale == 'de'">
            
            <h4>1. Name und Anschrift</h4>
            <p>Würth AG<br>Dornwydenweg 11<br>4144 Arlesheim<br>Schweiz<br><a href="#" onclick="mailthis('info [at] wuerth-ag [dot] ch');return false;" target="_blank">info@wuerth-ag.ch</a></p>
            <h4>2. Geschäftsführer</h4>
            <p>Florian Uhlmann, CEO<br>Lukas Wagner, VR</p>
            <h4>3. Beauftragte Personen</h4>
            <p>Roland Eichenberger - Mitarbeitende / Bewerber<br>Natalie Manfron - Kunden / Internet / Intranet / Online-Shop<br>Philip Duffner - Lieferanten / Anbieter / Spediteure</p>
            <h4>4. Zweckbestimmung der Datenerhebung, -verarbeitung oder –nutzung</h4>
            <p>Würth AG mit Sitz in Arlesheim (im Kanton Baselland) beliefert Profi-Handwerker aller Branchen mit Befestigungs- und Montagematerial. Das Verkaufsprogramm umfasst über 150 Teile und Ab-messungen: Schrauben, -zubehör, Dübel, chemisch-technische Produkte, Möbel- und Baubeschläge, Werkzeuge, Maschinen, Installationsmaterial, Arbeitsschutz, Fahrzeugeinrichtungen und Lagermanagement. Die Produkte werden über den Aussendienst von Würth AG an Handwerker vertreiben.</p>
            <p>Das Unternehmen erhebt, verarbeitet und nutzt personenbezogener Daten ausschliesslich zur Erfül-lung oder Vorbereitung von Verträgen, zur Einhaltung gesetzlicher Verpflichtungen oder mit der Einwilligung der Betroffenen.</p>
            <h4>5. Betroffenen Personengruppen</h4>
            <h5>5.1 Mitarbeitende</h5>
            <ul><li class="bulletList">Gegenwärtige Mitarbeitende</li>
            <li class="bulletList">Ehemalige Mitarbeitende</li>
            <li class="bulletList">Bewerber</li></ul><h5>5.2 Kunden</h5>
            <ul><li class="bulletList">Kunden</li>
            <li class="bulletList">Interessierte</li></ul><h5>5.3 Lieferanten</h5>
            <ul><li class="bulletList">Dienstleister</li>
            <li class="bulletList">Anbieter</li>
            <li class="bulletList">Spediteure</li></ul><p>Die diesbezüglichen Daten umfassen alle personenbezogenen Daten, die zur Erfüllung der jeweiligen Zweckbestimmung erforderlich sind.</p>
            <h4>6. Potenzielle Empfänger bei Datenübermittlung</h4>
            <p>Die potenziellen Empfänger übermittelter personenbezogener Daten sind:</p>
            <ul><li class="bulletList">Öffentliche Stellen, sofern eine gesetzliche Pflicht besteht</li>
            <li class="bulletList">Stellen innerhalb der Würth-Gruppe, Dienstleister und andere Geschäftspartner soweit es zur Erfüllung der jeweiligen Zweckbestimmung erforderlich ist und eine Rechtsvor-schrift dies erlaubt oder gebietet oder der Betroffene eingewilligt hat.</li>
            <li class="bulletList">Eine darüberhinausgehende Weitergabe personenbezogener Daten ist nicht vorgesehen und erfolgt somit auch nicht.</li></ul>
            <h4>7. Regelfristen für die Löschung</h4>
            <p>Die Löschung personenbezogener Daten erfolgt nach den jeweiligen geltenden gesetzlichen oder vertraglichen Regelungen zur Datenlöschung unter Berücksichtigung gesetzlicher oder vertraglicher Aufbewahrungspflichten.</p>
            <h4>8. Geplante Datenübermittlung in Drittstaaten</h4>
            <p>Sollten Datenübermittlungen in Drittstaaten erforderlich werden, so geschieht dies ausschliesslich zu Abschluss oder Erfüllung von Verträgen soweit das schutzwürdige Interesse des Betroffenen dem nicht entgegensteht. Bei der Abwägung von Interessen der betroffenen und der Vertragsparteien wird stets ein strenger Massstab zu Gunsten des Betroffenen angelegt.</p>

          </div>

          <div v-if="locale == 'fr'">
            <h4>1. Nom et adresse </h4>
            <p>Würth AG<br>Dornwydenweg 11<br>4144 Arlesheim<br>Suisse<br><a href="#" onclick="mailthis('info [at] wuerth-ag [dot] ch');return false;" target="_blank">info@wuerth-ag.ch</a></p>
            <h4>2. Gérants</h4>
            <p>Florian Uhlmann, CEO<br>Lukas Wagner, VR</p>
            <h4>3. Personnes mandatées</h4>
            <p>Roland Eichenberger - collaborateurs / candidats<br>Natalie Manfron - clients / Internet / intranet / E-Shop<br>Philip Duffner - fournisseurs / prestataires / transitaires</p>
            <h4>4. Finalité de la collecte, du traitement ou de l’utilisation des données</h4>
            <p>Würth AG, dont le siège est à Arlseheim (canton de Bâle-Campagne), est fournisseur de matériel de fixation et de montage pour les professionnels de toutes les branches. Le programme de vente com-prend plus de 150 pièces et éléments tels que des vis et accessoires, chevilles, produits chimico-techniques, ferrures pour meubles et la construction, outillages, machines, matériel d’installation, protections de travail, équipements de véhicules et systèmes de gestion de stock. Les produits sont vendus aux artisans par les collaborateurs du service externe de Würth AG.</p>
            <p>L’entreprise collecte, traite et utilise les données personnelles exclusivement pour l’exécution ou la préparation de contrats, pour respecter les obligations légales ou avec le consentement des personnes concernées.</p>
            <h4>5. Groupes de personnes concernés</h4>
            <h5>5.1 Collaborateurs</h5>
            <ul><li class="bulletList">Collaborateurs actuels</li>
            <li class="bulletList">Anciens collaborateurs</li>
            <li class="bulletList">Candidats</li></ul><h5>5.2 Clients</h5>
            <ul><li class="bulletList">Clients</li>
            <li class="bulletList">Personnes intéressées</li></ul><h5>5.3 Fournisseurs</h5>
            <ul><li class="bulletList">Prestataires de services</li>
            <li class="bulletList">Fournisseurs</li>
            <li class="bulletList">Transitaires</li></ul><p>Les données y relatives englobent toutes les données personnelles, qui sont nécessaires pour l’accomplissement de la finalité respective.</p>
            <h4>6. Destinataires potentiels des données transmises</h4>
            <p>Les destinataires potentiels des données personnelles transmises sont:</p>
            <ul><li class="bulletList">Les organismes publics, dans la mesure où il existe une obligation légale</li>
            <li class="bulletList">Les organismes au sein du groupe Würth, les prestataires de services et autres parte-naires commerciaux, dans la mesure nécessaire pour l’accomplissement de la finalité respective et lorsqu’une disposition légale le permet ou l’exige ou que la personne con-cernée y a consenti.</li>
            <li class="bulletList">Hors de ce cadre, la transmission des données personnelles n’est ni prévue ni effectuée.</li></ul>
            <h4>7. Délais réglementaires pour la suppression</h4>
            <p>La suppression des données personnelles est effectuée selon les dispositions légales ou contrac-tuelles respectives valables relatives à la suppression des données, compte tenu des obligations légales ou contractuelles de conservation.</p>
            <h4>8. Transmission des données vers des états tiers</h4>
            <p>Si des transmissions de données vers des états tiers devaient être nécessaires, cela s’effectue unique-ment à des fins de conclusion ou d’exécution de contrats, à condition de ne pas porter atteinte aux intérêts de la personne concernée. Lors de la prise en considération des intérêts des personnes con-cernées et des parties contractantes, des critères stricts sont toujours appliqués en faveur de la personne concernée.</p>
  
          </div>

          <div v-if="locale == 'it'">
                        
            <h4>1. Nome ed indirizzo </h4>
            <p>Würth AG<br>Dornwydenweg 11<br>4144 Arlesheim<br>Svizzera<br><a href="#" onclick="mailthis('info [at] wuerth-ag [dot] ch');return false;" target="_blank">info@wuerth-ag.ch</a></p>
            <h4>2. Amministratori</h4>
            <p>Florian Uhlmann, CEO<br>Lukas Wagner, VR</p>
            <h4>3. Persone incaricate</h4>
            <p>Roland Eichenberger - Collaboratori / candidati<br>Natalie Manfron - Clienti / Internet / Intranet / e-Shop<br>Philip Duffner - Fornitori / offerenti / spedizionieri</p>
            <h4>4. Finalità del rilevamento, trattamento o utilizzo dei dati</h4>
            <p>Würth AG, con sede ad Arlesheim (cantone Basilea Campagna), rifornisce gli artigiani di tutti i set-tori di materiale di fissaggio e montaggio. Il programma di vendita comprende oltre 150 parti e dimensioni: viti, accessori, tasselli, prodotti chimici, elementi di raccordo per l’edilizia e l’arredamento, attrezzi, macchine, materiale da installazione, protezione sul lavoro, allestimento per veicoli e gestione magazzino. I prodotti vengono venduti agli artigiani dal personale in servizio fuori sede della Würth AG.</p>
            <p>Una volta ottenuto il consenso delle persone interessate, l'azienda rileva, tratta e utilizza i dati personali esclusivamente per onorare ed elaborare contratti e per ottemperare agli obblighi di legge.</p>
            <h4>5. Gruppi di persone interessate</h4>
            <h5>5.1 Collaboratori</h5>
            <ul><li class="bulletList">Collaboratori attuali</li>
            <li class="bulletList">Ex collaboratori</li>
            <li class="bulletList">Candidati</li></ul><h5>5.2 Clienti</h5>
            <ul><li class="bulletList">Clienti</li>
            <li class="bulletList">Persone interessate</li></ul><h5>5.3 Fornitori</h5>
            <ul><li class="bulletList">Fornitori di servizi</li>
            <li class="bulletList">Offerenti</li>
            <li class="bulletList">Spedizionieri</li></ul><p>I dati al riguardo comprendono tutti i dati personali necessari per conseguire la specifica finalità.</p>
            <h4>6. Potenziali destinatari dei dati trasmessi</h4>
            <p>I potenziali destinatari dei dati personali trasmessi sono:</p>
            <ul><li class="bulletList">Enti pubblici, se ne esiste l'obbligo di legge</li>
            <li class="bulletList">Uffici interni al gruppo Würth, fornitori di servizi e altri partner commerciali, se necessa-rio per conseguire la finalità specifica e se permesso o imposto da una norma giuridica o consentito dalla persona interessata.</li>
            <li class="bulletList">Una cessione di altro genere dei dati personali non è prevista e non viene nemmeno effettuata.</li></ul>
            <h4>7. Termini per la cancellazione</h4>
            <p>La cancellazione dei dati personali avviene in conformità alle disposizioni di legge o contrattuali in materia di cancellazione dei dati e nel rispetto degli obblighi di archiviazione stabiliti dalla legge o dalle clausole del contratto.</p>
            <h4>8. Trasmissione dei dati pianificata in paesi terzi</h4>
            <p>L'eventuale trasmissione dei dati in paesi terzi avviene soltanto se è necessaria per la stipulazione o l'esecuzione di contratti e solo se non viola e non è contraria gli interessi sensibili della persona inte-ressata. Nel valutare e controbilanciare gli interessi degli interessati e delle parti contraenti si adot-tano sempre severi criteri a favore della persona interessata.</p>

          </div>

          <p>&nbsp;</p>

        </div>
      </div>
    </div>

  </transition-group>
</template>

<script>
import { Trans } from '../plugins/Translation.js'
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      displayMainView: true,
      displayMsgView: false
    }
  },
  computed: {
      locale () {
        return Trans.currentLocale
      }
    },
  methods: {

  }
}
</script>