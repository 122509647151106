<template>
  <main id="app-main">
    <!--- // HEADER (STICKY) // --->
    <header id="app-header" data-border="true" data-shadow="true">
      <div class="container-fluid">
        <div class="header-inner app-spacing">
          <div class="header-logos">
            
            <h4 class="logo-copyright">
              <router-link :to="$i18nRoute({ name: 'Home' })">
                PROFESSIONAL CAR CARE<sup>by Würth</sup>
              </router-link>
            </h4>
            <h4 v-if="loggedIn" class="logo-owner">{{ loggedInUser.company }}</h4>
            
          </div> <!--- /.header-logos --->
          <div class="header-control">
          
            <div class="control-element" v-if="cartCount">
              <router-link v-if="loggedIn" :to="$i18nRoute({ name: 'Cart' })">
                <span class="basket-dropped" v-if="dropPackage">
                  <em v-html="$t('package.addedtocart')"></em>
                </span>
                <i class="far fa-shopping-basket"></i>
                <em>{{ cartCount }}</em>
              </router-link>
            </div>
            
            <div class="control-element">
              <router-link v-if="!loggedIn" :to="$i18nRoute({ name: 'Login' })" class="btn btn-primary">{{ $t('menu.login') }}</router-link>
            </div>

            <div class="control-element" v-if="loggedIn">
              <div class="dropdown">
                <a href="##" data-trigger="menu-meta" data-toggle="dropdown">
                  <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                  </div>
                </a>

                <div class="dropdown-menu">
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'Home' })" class="link-icon">
                        <i class="far fa-home"></i>
                        <span>{{ $t('menu.home') }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'MyAccount' })" class="link-icon">
                        <i class="far fa-user"></i>
                        <span>{{ $t('menu.myaccount') }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'Settings' })" class="link-icon">
                        <i class="far fa-cog"></i>
                        <span>{{ $t('menu.settings') }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'Orderhistory' })" class="link-icon">
                        <i class="far fa-shopping-basket"></i>
                        <span>{{ $t('menu.orderhistory') }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'Logout' })" class="link-icon link-alternative link-logout"><i class="far fa-sign-out-alt"></i>
                        <span>{{ $t('menu.logout') }}</span></router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="control-element">
              <div class="dropdown">
                <a href="##" data-trigger="menu-meta" data-toggle="dropdown">
                  <i class="fa fa-language" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu">
                  <ul>
                    <li v-for="locale in supportedLocales" :key="locale">
                      <a @click="switchLocale(locale)" class="link-icon">
                                <span>{{ $t('menu.' + locale) }}</span>
                            </a>
                          </li>
                  </ul>
                </div>
              </div>
            </div> <!--- /.control-element --->

            
          </div> <!--- /.header-control --->
        </div> <!--- /.header-inner --->
      </div> <!--- /.container-fluid --->
    </header> <!--- /#app-header --->

    <main id="app-content" v-if="displayMainApp">

      <div class="app-content-inner">
            <div class="container-fluid">
              <div class="content-inner app-spacing">

      <!--- <main-app></main-app> --->

            <template v-if="$route.matched.length">
                <router-view></router-view>
              </template>
              <template v-else>
                <p>You are logged {{ loggedIn ? 'in' : 'out' }}</p>
              </template>

          </div>
        </div>
      </div>

    </main>     

    <!--- // FOOTER (STICKY) // --->

    <footer id="app-footer" data-border="true" data-shadow="true">
      <div class="container-fluid">
        <div class="footer-inner app-spacing">

          <div class="footer-left">
            <ul>
              <li><a class="footer-logo"></a></li>
              <li><router-link :to="$i18nRoute({ name: 'Imprint' })">{{ $t('menu.imprint') }}</router-link></li>
              <li><router-link :to="$i18nRoute({ name: 'Dataprotection' })">{{ $t('menu.dataprotection') }}</router-link></li>
            </ul>
          </div>

          <div class="footer-right">
            <span>Nicht angemeldet - Bitte <router-link :to="$i18nRoute({ name: 'Login' })" class="link-underline">anmelden</router-link> oder <router-link :to="$i18nRoute({ name: 'Register' })" class="link-underline">registrieren</router-link></span>
          </div>
        
        </div> <!--- /.footer-inner --->
      </div> <!--- /.container-fluid --->
    </footer> <!--- /#app-footer --->

    <!--- // APP BACKGROUND (CUSTOM IMAGE + OVERLAY) // --->

    <div class="app-background" :style="userBgImage()">
      <div class="overlay" style="background-color: #b2b2b2; opacity: 1; display: none;"></div>
      <div class="overlay" style="background-color: #000; opacity: .25; display: block;"></div>
    </div> <!--- /.app-background --->
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import auth from '@/auth.js'
import store from '@/store'
//import mixin from '@/mixins'
import { Trans } from '@/plugins/Translation.js'

export default {
  data() {
    return {
      displayMainApp:true,
      displaySectionList:true,
      loggedIn: auth.loggedIn()
    }
  },
  computed: {
    count () {
      return store.state.count
    },
    cartCount () {
      return store.state.cartCount
    },
    dropPackage () {
      return store.state.dropPackage
    },
    loggedInUser () {
      return store.state.loggedInUser
    },
    supportedLocales () {
      return Trans.supportedLocales
    }
    
  },
  methods: {
    increment () {
      store.commit('increment')
    },
    decrement () {
      store.commit('decrement')
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: {locale} })
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location)
        })
      }
    },
    userBgImage(){
        if (store.state.loggedInUser.bgImage != undefined && store.state.loggedInUser.bgImage != '')
            return 'background-image: url("/images/backgrounds/' + store.state.loggedInUser.bgImage + '")'
        return 'background-image: url("/images/backgrounds/1.jpg")'
    }
  },
  watch: {
    $route(to, from) {
      store.state.dropPackage = false
    }
  }
}
</script>