import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//import Home from '../views/Home.vue'
import de from '../locales/de.js'
import fr from '../locales/fr.js'
import it from '../locales/it.js'
import { i18n } from '../plugins/i18n.js'
import auth from '../auth.js'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import ForgotPassword from '../views/forgotpassword.vue'
import ResetPassword from '../views/resetpassword.vue'
import MyAccount from '../views/myaccount.vue'
import Settings from '../views/settings.vue'
import Orderhistory from '../views/orderhistory.vue'
import Cart from '../views/cart.vue'
import Checkout from '../views/checkout.vue'
import CheckoutReview from '../views/checkoutreview.vue'
import CheckoutSubmit from '../views/checkoutsubmit.vue'
import Section from '../views/section.vue'
import ArticlePackage from '../views/articlepackage.vue'
import MainApp from "../views/mainapp.vue"
import Static from "../views/static.vue"
import Imprint from "../views/imprint.vue"
import Dataprotection from "../views/dataprotection.vue"
import { Trans } from '../plugins/Translation'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

function requireAuth (to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: '/' + to.params.locale + '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}

function setLocale(to, from, next) {
  let { locale } = to.params;
  if (!locale) {
    locale = 'de';
  }

  // Do something with locale, check availability of messages etc.
  i18n.locale = locale;
  next();
}

const routes = [{
    path: '/:locale(de|fr|it)?',
    component: {
      beforeRouteEnter: setLocale,
      beforeRouteUpdate: setLocale,
      render(h) { return h('router-view'); }
    },

    beforeEnter: Trans.routeMiddleware,
    children: [
      { path: '',         name: 'Home',         component: MainApp      },
      //{ path: 'home',       name: 'Home',         component: MainApp      },
      { path: 'login',      name: 'Login',        component: Login      },
      { path: 'register',     name: 'Register',       component: Register     },
      { path: 'forgotpassword', name: 'ForgotPassword',   component: ForgotPassword   },
      { path: 'resetpassword',  name: 'ResetPassword',    component: ResetPassword  },
      { path: 'myaccount',    name: 'MyAccount',      component: MyAccount,       beforeEnter: requireAuth    },
      { path: 'settings',     name: 'Settings',       component: Settings,    beforeEnter: requireAuth  },
      { path: 'orderhistory',   name: 'Orderhistory',     component: Orderhistory,  beforeEnter: requireAuth  },
      { path: 'section/:id',    name: 'Section',      component: Section,  beforeEnter: requireAuth  },
      { path: 'package/:id',  name: 'Package',      component: ArticlePackage,  beforeEnter: requireAuth  },
      { path: 'cart',       name: 'Cart',         component: Cart,      beforeEnter: requireAuth  },
      { path: 'checkout',     name: 'Checkout',       component: Checkout,    beforeEnter: requireAuth  },
      { path: 'checkoutreview', name: 'CheckoutReview',   component: CheckoutReview,  beforeEnter: requireAuth  },
      { path: 'checkoutsubmit', name: 'CheckoutSeview',   component: CheckoutSubmit,  beforeEnter: requireAuth  },
      //{ path: 'imprint',    name: 'Imprint',      component: Static,      props: { content: 'imprint' } },
      { path: 'imprint',    name: 'Imprint',      component: Imprint,     },
      { path: 'dataprotection', name: 'Dataprotection',   component: Dataprotection   },
        { path: 'logout',     name: 'Logout', 
          beforeEnter (to, from, next) {
            auth.logout()
            //next('/')
            //next('/' + to.params.locale + '/')
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: ".",
  routes: routes
})

export default router
