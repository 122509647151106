<template>
  	<div class="row row-50px">
		<div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
			<div class="c-boxed c-rounded c-shadow" data-background="white">
				<h1>{{ $t('menu.orderhistory') }}</h1>

				<fieldset>

					<div class="basket-products" v-if="myorders.length > 0">
						<table cellpadding="0" cellspacing="0" class="pt-2">
							<thead>
								<tr>
									<th align="left">
										{{ $t('orderhistory.orderdate') }}
									</th>
									<th align="left">
										{{ $t('orderhistory.ordernumber') }}
									</th>
									<th align="left">
										{{ $t('main.customername') }}
									</th>
									<th align="left">
										{{ $t('main.customercarplate') }}
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								
								<tr v-for="order in myorders">
									<td align="left" style="text-align: left">
										{{ order.datecreated | formatDate }}
									</td>
									<td align="left" style="text-align: left">
										<span>{{ order.ordernumber.substring(14,21) }}</span>
									</td>
									<td align="left" style="text-align: left">
										{{ order.customername }}
									</td>
									<td align="left" style="text-align: left">
										{{ order.customercarplate }}
									</td>
									<td align="left" style="text-align: left" class="pr-3">
										
										<button
									      type="button"
									      class="btn"
									      @click="showModal(order.id)"
									    >
									      <i class="fa fa-info"></i>
									    </button>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="basket-price">
							<!-- <h2>{{ formatPrice(totalPrice) }}</h2>
							<p class="flex-set text-right btn-set">
								<router-link :to="$i18nRoute({ name: 'Checkout' })" class="btn btn-lg btn-primary">{{ $t('cart.continuetoorder') }}</router-link>
							</p> -->
						</div> 

						<Modal 
							:order="currentorder"
						  	v-show="isModalVisible"
						  	@close="closeModal">

						  <template v-slot:header v-if="isModalVisible">
						  	<h1>{{ $t('main.order') }} {{ currentorder.ordernumber.substring(14,21) }}</h1>
						  </template>

						  <template v-slot:body v-if="isModalVisible">

						  	<dl class="row">
						  		<dt class="col-4 col-md-3">{{ $t('orderhistory.ordernumber') }}: </dt><dd class="col-8 col-md-9">{{ currentorder.ordernumber.substring(14,21) }}</dd>
						  		<dt class="col-4 col-md-3">{{ $t('orderhistory.orderdate') }}: </dt><dd class="col-8 col-md-9">{{ currentorder.processingdate | formatDate }}</dd>
						  	</dl>

						    <table class="table table-striped">
						    	<thead>
				              		<tr>
				              			<th colspan="3">{{ $t('main.packageslist') }}</th>
				              		</tr>
				              	</thead>
						    	<tbody>
				              		<tr v-for="itm in currentorder.items">
				              			<td>{{ itm.sectionname }}</td>
				              			<td>{{ itm.packagename }}</td>
				              			<td align="right">{{ formatPrice(itm.price) }}</td>
				              		</tr>
				              	</tbody>
				              	<tfoot>
				              		<tr>
				              			<td colspan="3" align="right">Total: <b>CHF {{ formatPrice(currentorder.totalPrice) }}</b>
				              				<br/><small class="d-block">{{ $t('cart.mwsttext') }}</small>
				              			</td>
				              		</tr>
				              	</tfoot>
				            </table>
						  </template>

						</Modal>

					</div>

					<div v-else class="navbar-dropdown is-boxed is-right">
			            <a class="navbar-item" href="">
			                
			            </a>
			        </div>

				</fieldset>

			</div>

		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'
import Modal from '../components/Modal.vue'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
});

export default {
  data () {
    return {
      myorders: [],
      isModalVisible: false,
      currentorder: {}
    }
  },
  components: {
      Modal
  },
  computed: {
	locale () {
      return Trans.currentLocale
    }
  },
  methods: {
  	showModal(orderid) {
    	this.loading = true

	    axios({
	        method: 'get',
	        url: `${process.env.VUE_APP_API_URL}/API/order/${orderid}/${this.locale}/`
	    })
	    .then(response => {
	      	if (response.data.success) {
	        	this.currentorder = response.data.order
	        	this.isModalVisible = true
	        }
	        this.loading = false
	    })
	    .catch(error => {
	        if (error.response.status) {
	          window.location.href = "/" + this.locale + "/orderhistory"
	        }
	        this.loading = false
	    })
  	},
  	closeModal() {
    	this.isModalVisible = false
  	},
  	getOrders() {
      this.loading = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/API/orders/${this.locale}/`
      })
      .then(response => {
      	if (response.data.success) {
        	this.myorders = response.data.orders
        }
        this.loading = false
      })
      .catch(error => {
        if (error.response.status) {
          window.location.href = "/" + this.locale + "/"
        }
        this.loading = false
      })
    },
    currentDate() {
      const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      return date
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2)
      let newval = ' '+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      return newval
  	}
  },
  mounted() {
    this.getOrders()
  }
}
</script>