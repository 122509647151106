export default {
  "lang": "fr",
  "back": "REVENIR",
  "passwordtip": "Min. 8 caractères, 1 lettre minuscule, 1 lettre majuscule, 1 chiffre, 1 caractère spécial",
  "menu": {
    "de": "Allemand",
    "fr": "Français",
    "it": "Italien",
    "home": "Home",
    "login": "Login",
    "myaccount": "Mon compte",
    "settings": "Les paramètres",
    "orderhistory": "Historique des commandes",
    "logout": "Se déconnecter",
    "forgotpassword": "Mot de passe",
    "resetpassword": "Réinitialiser le mot de passe",
    "imprint": "Mentions légales",
    "dataprotection": "Déclaration de confidentialité"
  },
  "static": {
    "content": {
      "imprint": "IMPRESSUM TEXT IN FRENCH",
      "dataprotection": "DATENSCHUTZ TEXT IN FRENCH"
    }
  },
  "main": {
    "title": "LE SYSTÈME DE COMMANDE PROFESSIONNEL POUR LA PRÉPARATION DES VÉHICULES DE VOS CLIENTS",
    "button": "Enregistrerz-vous maintenant",
    "content": "<p><b>PROFESSIONAL CAR CARE by Würth</b> offre à ses entreprises partenaires un concept prêt à l'emploi de préparation de véhicules pour leurs clients finaux. L'app y associée soutient le partenaire dans le traitement et la vente des services de préparation de véhicules pour ses clients et simplifie considérablement les opérations de sorte que le chiffre d'affaires supplémentaire visé puisse être atteint efficacement.</p><p>Nous entrons en jeu là où le client en retire quelque chose: l'apparence de son véhicule. Un véhicule nettoyé et entretenu professionnellement n'est pas seulement beau, il semble aussi plus précieux, ce qui peut en fin de compte augmenter la valeur de revente.</p><p>Avons-nous suscité votre intérêt? Contactez-nous à l'adresse <a href='mailto:professionalcarcare@wuerth-ag.ch'>professionalcarcare@wuerth-ag.ch</a></p>",
    "new_message": "no messages | one message | {count} messages",
    "email": "Adresse e-mail",
    "fullname": "Prénom / Nom",
    "company": "Entreprise",
    "zipcity": "Code postal / ville",
    "phonenumber": "Téléphone",
    "address": "Adresse",
    "password": "Mot de passe",
    "confirmpassword": "Confirmer le mot de passe",
    "customernumber": "Numéro de client",
    "customerdata": "Données client",
    "customername": "Nom du client",
    "customercarplate": "Plaque d'immatriculation",
    "accessdata": "Données d'accès",
    "packageslist": "Forfaits de nettoyage",
    "legitimation": "Légitimation",
    "save": "SAUVEGARDER",
    "chooseor": "ou",
    "backtoselection": "RETOUR À LA SÉLECTION",
    "processingdate": "Date d'éxecution",
    "comments": "Remarques",
    "selectdatetime": "Sélectionnez la date et l'heure",
    "now": "Maintenant",
    "order": "Ordre"
  },
  "login": {
    "title": "",
    "welcome": "Veuillez vous connecter avec vortre Login :",
    "requiredlogin": "Vous devez d'abord vous connecter."
  },
  "register": {
    "title": "ENREGISTREMENT",
    "button": "Continuer",
    "successmsg": "Utilisateur créé avec succès. Une fois votre compte activé, vous serez averti par e-mail."
  },
  "forgotpassword": {
    "title": "RÉINITIALISER LE MOT DE PASSE",
    "linktologin": "Vers la connexion",
    "successmsg": "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail."
  },
  "resetpassword": {
    "successmsg": "Votre mot de passe a été réinitialisé."
  },
  "myaccount": {
    "successmsg": "L'utilisateur a été mis à jour avec succès."
  },
  "settings": {
    "hourlyrate": "Taux horaire",
    "backgroundimg": "Image de fond",
    "choosefile": "Choisissez un fichier",
    "successmsg": "L'utilisateur a été mis à jour avec succès.",
    "clearimage": "Image claire",
    "availablepackages": "Prestations de service"
  },
  "package": {
    "title": "Les services en un coup d'œil",
    "selectvehiclesize": "Sélectionnez la taille du véhicule :",
    "addedtocart": "Le produit a été<br>ajouté au panier",
    "addtocart": "AJOUTER AU PANIER",
    "mwsttext": "Tous les prix hors TVA"
  },
  "cart": {
    "title": "Panier",
    "emptycart": "Votre panier est vide",
    "continuetoorder": "Continuer la commande",
    "mwsttext": "Tous les prix hors taxes"
  },
  "checkout": {
    "title": "Informations sur votre client"
  },
  "checkoutreview": {
    "title": "APERÇU DE LA COMMANDE",
    "button": "FINALISER LA COMMANDE"
  },
  "checkoutsubmit": {
    "title": "Commande terminée",
    "thankyou": "<p>Merci pour votre commande !<br> <br> Vous recevrez une confirmation de commande par email.</p>"
  },
  "orderhistory": {
  	"orderdate": "Date",
  	"ordernumber": "Commande"
  },
  "error": {
    "msgs": {
      "isrequired": "{fld} est requis",
      "invalidemail": "Adresse e-mail invalide",
      "invalidcustomernumber": "Numéro de client invalide.",
      "badlogininformation": "Mauvaises informations de connexion",
      "PASSWORD_INSECURE": "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre, 1 caractère spécial et au minimum 8 caractères.",
      "EMAIL_ALREADY_REGISTERED": "E-mail déjà enregistré",
      "EMAIL_INVALID": "Adresse e-mail invalide",
      "PASSWORD_MISMATCH": "Les champs de mot de passe ne correspondent pas.",
      "INCORRECT_PASSWORD": "Mot de passe incorrect",
      "KEY_INVALID": "Votre Login a expiré. ",
      "USER_NOT_ACTIVATED": "UTILISATEUR NON ACTIVÉ",
      "USER_NOT_FOUND": "L'utilisateur n'a pas été trouvé."
    }
  }
  
}
