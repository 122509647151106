<template>
	<transition-group name="fade">
	
    <div class="row" key="main">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

        <div class="c-boxed c-rounded c-shadow" data-background="white">

          <h1>{{ $t('menu.'+this.content) }}</h1>

          <div v-html="$t('static.content.'+this.content)"></div>

          <p>&nbsp;</p>

        </div>
      </div>
    </div>

  </transition-group>
</template>

<script>
import { Trans } from '../plugins/Translation.js'
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      displayMainView: true,
      displayMsgView: false
    }
  },
  computed: {
      locale () {
        return Trans.currentLocale
      }
    },
  methods: {

  }
}
</script>