<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
      <div class="c-boxed c-rounded c-shadow" data-background="white">
        <h1>{{ $t('menu.settings') }}</h1>

        <form v-on:submit.prevent="update">

          <fieldset>
            <legend>1. {{ $t('settings.hourlyrate') }} in CHF</legend>

            <div class="form-group">
              <input type="text" 
                :class="{ 'is-invalid' : errors.hourlyrate }"
                v-model="user.hourlyrate" 
                :placeholder="$t('settings.hourlyrate')" 
                class="form-control" 
                autocomplete="off">
              <small v-if="errors.hourlyrate" class="invalid-feedback d-block">{{ errors.hourlyrate }}</small>
            </div>
          </fieldset>

          <fieldset>
            <legend>2. {{ $t('settings.backgroundimg') }} (1600 x 1067px)</legend>

            <div class="form-group">
              <input type="file" accept="image/*" 
                @change="previewImage"
                class="form-control-file" 
                id="files" 
                ref="files"
                data-invalid-feedback="Bitte wählen Sie eine gültige JPG, PNG Datei (*.jpg, *.png) aus!" 
                :placeholder="this.$t('settings.choosefile')">

              <p>{{ imgprev }}</p>
              
            </div>

            <button type="button" v-if="(this.user.bgImage && this.user.bgImage != '') || bgImage" class="btn btn-sm btn-secondary" @click="reset"><small>{{ $t('settings.clearimage') }}</small></button>

          </fieldset>

          <fieldset>
            <legend>3. {{ $t('settings.availablepackages') }}</legend>

            <div class="accordion" id="accordionPackages">

              <div class="card" v-for="section, index in sections">
                <div class="card-header" :id="'sectionheading'+index">
                  <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#pckgcollapse'+index" aria-expanded="true" :aria-controls="'#pckgcollapse'+index">
                      {{ section.name }}
                    </button>
                  </h2>
                </div>

                <div :id="'pckgcollapse'+index" 
                  class="collapse show"
                  :aria-labelledby="'sectionheading'+index" data-parent="#accordionPackages">
                  <div class="card-body">

                    <div class="checkbox-set">
                      <div class="form-group my-2" v-for="pckg, pindex in section.packages">

                        <div class="row">

                          <div class="col-lg-1 col-sm-2 col-3">
                            <ToggleButton :id="'package'+index+'_'+pindex"
                              v-bind:value="pckg.id"
                              v-bind:default-state="user.package_lst && user.package_lst.includes(pckg.id.toString())" 
                              v-model="user.package_lst" 
                              v-on:change="togglePackage(pckg.id)" 
                            /> 
                          </div>
                          <div class="col-lg-11 col-sm-10 col-9">
                            <span>{{ pckg.name }}</span>
                          </div>
                        </div>
                        
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              
            </div>
          </fieldset>

          <p class="flex-set text-right btn-set">
            <button type="submit" class="btn btn-primary">{{ $t('main.save') }}</button>
          </p>

          <p v-if="!error && msg">{{ msg }}</p>

        </form>
      </div>

    </div>
  </div>  
</template>

<script>
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'
import ToggleButton from '../components/ToggleButton.vue'

export default {
  data () {
    return {
      errors: {},
      error: false,
      msg: "",
      sections: [],
      setform: {email: ""},
      preview: null,
      imgprev: null,
      bgImage: null,
      resetImg: false
    }
  },
  components: {
    ToggleButton
  },
  computed: {
    user () {
      return this.$store.getters.loggedInUser
    },
    locale () {
      return Trans.currentLocale
    }
  },
  methods: {
    togglePackage(value) {
      if (this.user.package_lst.includes(value.toString()))
        this.user.package_lst.splice(this.user.package_lst.indexOf(value.toString()), 1);
      else 
        this.user.package_lst.push(value.toString())
    },
    getSections() {
      this.loading = true
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/API/sections/${this.locale}/`
      })
      .then(response => {
        this.sections = response.data;
        this.loading = false
      })
      .catch(error => {
        if (error.response.status) {
          delete localStorage.token
          delete localStorage.loggedInUser
          window.location.href = "/" + this.locale + "/"
        }
        this.loading = false
      })
    },
    bgImagePlaceholder () {
      this.imgprev = this.user.bgImage ? this.user.bgImage.substring(0,this.user.bgImage.indexOf('?v=')) : ''
    },
    previewImage (event) {

      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.bgImage=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }

      if( ! (this.$refs.files.files[0].type == 'image/png' || this.$refs.files.files[0].type == 'image/jpeg' ) )
        return false;
      this.bgImage = this.$refs.files.files[0];

    },
    reset: function() {
      this.bgImage = null
      this.imgprev = this.$t('settings.choosefile')
      this.preview = null
      this.resetImg = true
    },

    update () {
      this.setting = true
      this.errors = {}

      if (this.user.hourlyrate === '')
        this.errors.hourlyrate = ' '

      if (Object.keys(this.errors).length !== 0)
        return false
     
      let formData = new FormData();
      formData.append('email', this.user.email);
      formData.append('hourlyrate', this.user.hourlyrate);
      formData.append('package_lst', this.user.package_lst);
      if (this.bgImage)
        formData.append('bgImage', this.bgImage);
      else
        formData.append('resetImg', this.resetImg);

      axios.post('/cfc/remote/users.cfc?method=update', formData,
        {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
          if(res.data.success) {
            this.msg = this.$t('settings.successmsg')
            this.$store.commit('setLoggedInUser', res.data.user)
          }
          else {
            this.error = true
            this.errors = res.data.errors
          }
        })
        .catch((error) => {
          this.error = true
        }).finally(() => {
          this.setting = false
        });
    }
  },
  mounted () {
    this.getSections()
    this.bgImagePlaceholder()
  },
  watch: {
    locale: function() {
       this.getSections()
    }
  }
}
</script>