<template>
  <div class="content-inner app-spacing">
    <div class="row">
      <div class="col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
        <div class="c-boxed c-rounded c-shadow" data-background="white">
          <h1>{{ $t('menu.forgotpassword') }}</h1>

          <form v-on:submit.prevent="forgotpassword">
            <div class="form-group-set">
              <div class="form-group">
                <input type="text" 
                  :class="{ 'is-invalid' : errors.customernumber }"
                  v-model="frm.customernumber" 
                  :placeholder="'Würth '+ $t('main.customernumber')" 
                  class="form-control" 
                  autocomplete="off"
                  maxlength="9">
                <small v-if="errors.customernumber" class="invalid-feedback d-block">{{ errors.customernumber }}</small>
              </div>
              <!-- <div class="form-or-spacer">
                <span>{{ $t('main.chooseor') }}</span>
              </div> -->
              <div class="form-group">
                <input type="text" 
                  :class="{ 'is-invalid' : errors.email }"
                  v-model="frm.email" 
                  :placeholder="$t('main.email')" 
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.email" class="invalid-feedback d-block">{{ errors.email }}</small>
              </div>
            </div>
            <p class="flex-set text-right">
              <router-link :to="$i18nRoute({ name: 'Login' })">{{ $t('forgotpassword.linktologin') }}</router-link>
              <button type="submit" class="btn btn-primary">{{ $t('forgotpassword.title') }}</button>
            </p>

            <p v-if="msg">{{ msg }}</p>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'

export default {
  data () {
    return {
      errors: {},
      frm: {email: '', customernumber: ''},
      error: false,
      msg: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  methods: {
    forgotpassword () {

      this.errors = {}

      let reqflds = ['email','customernumber']
      for( let fld in reqflds) {
        if (this.frm[reqflds[fld]] === '')
          this.errors[reqflds[fld]] = ' '
      }
      if (this.isEmailValid() !== '')
        this.errors.email = this.$t('error.msgs.invalidemail')
      if (this.frm.customernumber.length && (this.frm.customernumber.substr(0, 2) !== '36' || this.frm.customernumber.length != 9))
        this.errors.customernumber = this.$t('error.msgs.invalidcustomernumber')

      if (Object.keys(this.errors).length !== 0)
        return false

      this.frm.locale = this.locale

      axios.post('/cfc/remote/users.cfc?method=forgotpassword', this.frm)
         .then((res) => {
            if(res.data.success)
              this.msg = this.$t('forgotpassword.successmsg')
            else {
              this.error = true
              this.msg = this.$t('error.msgs.'+res.data.error)
            }
         })
         .catch((error) => {
            // error.response.status Check status code
            this.error = true
         }).finally(() => {
            //Perform action in always
            this.setting = false
         });
      
    },
    isEmailValid: function() {
      return (this.frm.email == "") ? "" : (this.reg.test(this.frm.email)) ? '' : 'is-invalid';
    }
  }
}
</script>