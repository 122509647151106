<template>
    <div class="row">
      <div class="col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
        <div class="c-boxed c-rounded c-shadow" data-background="white">
          <h1>{{ $t('menu.login') }}</h1>
          <p v-if="$route.query.redirect">
            {{ $t('login.requiredlogin') }}
          </p>
          <p>{{ $t('login.welcome') }}</p>
          <form @submit.prevent="login">
            <div class="form-group-set">
              <div class="form-group">
                <input type="text" 
                  :class="{ 'is-invalid' : errors.email }"
                  v-model="email" 
                  :placeholder="$t('main.email')" 
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.email" class="invalid-feedback d-block">{{ errors.email }}</small>
              </div>
              <div class="form-group">
                <input type="password" 
                  :class="{ 'is-invalid' : errors.pass }"
                  v-model="pass"
                  :placeholder="$t('main.password')"
                  class="form-control" 
                  autocomplete="off">
                <small v-if="errors.pass" class="invalid-feedback d-block">{{ errors.pass }}</small>
              </div>
            </div>
            <p class="flex-set text-right btn-set">
              <router-link :to="$i18nRoute({ name: 'ForgotPassword' })">{{ $t('menu.forgotpassword') }}</router-link>
              <button type="submit" class="btn btn-primary">{{ $t('menu.login') }}</button>
              <p v-if="error" class="error">{{ $t('error.msgs.badlogininformation') }}</p>
            </p>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import router from '../router'
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'

export default {
  data () {
    return {
      errors: {},
      email: '',
      pass: '',
      error: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  computed: {
    locale () {
      return Trans.currentLocale
    }
  },
  methods: {
    login () {
      
      this.errors = {}

      let reqflds = ['email','pass']
      for( let fld in reqflds) {
        if (this[reqflds[fld]] === '')
          this.errors[reqflds[fld]] = ' '
      }
      if (this.isEmailValid() !== '')
        this.errors.email = this.$t('error.msgs.invalidemail')

      if (Object.keys(this.errors).length !== 0)
        return false

      auth.login(this.email, this.pass, loggedIn => {
        if (!loggedIn) {
          this.error = true
        } else {
          router.replace(this.$route.query.redirect || '/')
          //const to = this.$router.resolve({ params: {locale} }) // <--------------------
          //this.$router.push(to.location)
        }
      })
    },
    isEmailValid: function() {
      return (this.email == "") ? "" : (this.reg.test(this.email)) ? '' : 'is-invalid';
    }
  }
}
</script>
