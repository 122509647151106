<template>
  <div class="row row-50px">
		<div class="col-12 col-sm-12 col-xl-8 offset-xl-2">
			<div class="c-boxed c-rounded c-shadow" data-background="white">
				<h1>{{ $t('checkoutreview.title') }}</h1>

				<fieldset>
					<legend>{{ $t('main.customerdata') }}</legend>
					<ul class="list-unstyled">
            			<li>{{ order.customername }}</li>
						<li>{{ order.customeremail }}</li>
           				<li>{{ order.customercarplate }}</li>
					</ul>
			        <legend v-if="order.processingdate != ''">{{ $t('main.processingdate') }}</legend>
			        <p v-if="order.processingdate != ''">{{ order.processingdate | formatDate }}</p>
			        <legend v-if="order.comments != ''">{{ $t('main.comments') }}</legend>
			        <p v-if="order.comments != ''">{{ order.comments }}</p>
				</fieldset>

				<fieldset class="mt-4">
					<legend>{{ $t('main.packageslist') }}</legend>

					<div class="basket-products" v-if="$store.state.cart.length > 0">
						<table cellpadding="0" cellspacing="0">
							<tbody>
								<tr v-for="cartitem in $store.state.cart">
									<td>
										<span>{{ cartitem.section }}</span>
										<strong>{{ cartitem.name }} - {{ cartitem.cartypename }}</strong>
									</td>
									<td>
										&nbsp;
									</td>
									<td style="width:180px">
										<em>{{ formatPrice(cartitem.price) }}</em>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="basket-price">
							<h2>{{ formatPrice(totalPrice) }}</h2>
							<p class="flex-set text-right btn-set">
								<button @click.prevent="checkout" class="btn btn-lg btn-primary">{{ $t('checkoutreview.button') }}</button>
							</p>
						</div> 
					</div>

					<div v-else class="navbar-dropdown is-boxed is-right">
	            <a class="navbar-item" href="">
	                Cart is empty
	            </a>
	        </div>

				</fieldset>

			</div>

		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import auth from '../auth.js'
import { Trans } from '../plugins/Translation.js'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
});

export default {
  computed: {
    locale () {
      return Trans.currentLocale
    },
    order () {
      return this.$store.getters.order
    },
    totalPrice() {
        let total = 0;
        for (let item of this.$store.state.cart) {
            total += parseFloat(item.totalPrice);
        }
        return total.toFixed(2);
    }
  },
  methods: {
  	checkout () {

      this.order.items = this.$store.state.cart.map(obj => {
        let rObj = {}
        rObj.packageId = obj.id
        rObj.carTypeId = obj.cartype
        rObj.price = obj.price
        return rObj
      })
      this.order.totalPrice = this.totalPrice
      this.order.locale = this.locale

      axios.post('/cfc/remote/orders.cfc?method=store', this.order)
         .then((res) => {
            if(res.data.success) {
              
              this.order = res.data.order

              this.$store.commit("setOrder", this.order)

              this.$store.commit("clearCart")

              this.$router.push({ path: 'checkoutsubmit' })
            }
              
            else
              this.error = true
         })
         .catch((error) => {
            this.error = true
         }).finally(() => {
            this.setting = false
         });

      
    },
  	formatPrice(value) {
      let val = (value/1).toFixed(2)
      let newval = ' '+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      let currsign ='CHF'        
      return currsign.concat(newval)
  	}
  }
}
</script>