import Vue from 'vue'
import de from '../locales/de.js'

const defaultImpl = VueI18n.prototype.getChoiceIndex

VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale !== 'de') {
    // proceed to the default implementation
    return defaultImpl.apply(this, arguments)
  }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
}

Vue.use(VueI18n)

const dateTimeFormats = {
  'de': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'fr': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'it': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }
}

const numberFormats = {
  'de': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'fr': {
    currency: {
      style: 'currency', currency: 'CHF'
    }
  },
  'it': {
    currency: {
      style: 'currency', currency: 'CHF'
    }
  }
}

export const i18n = new VueI18n({
  locale: 'de' || 'de',
  fallbackLocale: 'fr' || 'de',
  messages: { de },
  dateTimeFormats,
  numberFormats
})
